
.footeraddress {
    font-size: 16px;
    width: 100%;
    flex-basis: 27%;
    color: #ccc;
    height: auto !important;
}
.footer {
    background: rgb(51, 51, 56);
    color: rgb(127, 127, 127);
    padding: 20px 30px 20px;

}
.footerimg img {
    width: 60%;
    /* background: #fff; */
}
.footeraddress p, .footeraddress p a {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 10px;
    color: #ccc !important;
}

.footeraddress p:hover, .footeraddress p a:hover {
    color: #fff !important;
}
.copyright {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    width: 100%;
}
.socialicons {
    width: 100%;
    display: flex;
    border: none !important;
}
.linklist p {
    font-size: 14px;
    padding-right: 35px;
    text-align: justify;
}
.linklistul {
    flex-basis: 25%;
    height: auto !important;
    padding-left: 20px;
}
.linklistul ul {
    list-style-type: none;
    font-size: 13px;
    margin-bottom: 0;
}
.linklistul ul li {
    line-height: 2;
}
.linklistul ul li a{
    color: #ccc !important;
}
.socialicons a svg {
    font-size: 18px !important;
    color: #000;
    margin: 0px !important;
}
.footerimg {
    display: block;
}
.footerlogowrp{
    /* margin: auto; */
}
.linklist ul li {
    line-height: 2;
}

.flexboxlink {
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-top: 3px solid #fff;
}
.listlinkitemsbox {
    display: flex;
    max-width: 100% !important;
}
.linklist {
    margin-top: 35px;
}

.footer .container-fluid{
    max-width: 1220px;
}
.socialicons a{
    font-weight: 400!important;
    background: #ccc;
    font-size: 13px;
    color: rgb(51, 51, 56) !important;
    display: inline-flex;
    margin-right: 10px !important;
    padding: 10px !important;
    border-radius: 50%;
    margin-top: 20px;
}
.socialicons a:hover{
    background: #fff;
}

@media (max-width:786px) {
    .linklistul{
        padding-left: 0;
    }

    .footerimg img {
        width: 50% !important;
    }
    .listlinkitemsbox {
        display: flex ;
        margin-top: 0px !important;
    }
    .footeraddress p {
        margin-bottom: 10px !important;
        margin-top: 0px !important;
    }
}

@media (max-width:600px) {
    .listlinkitemsbox {
        display: block ;
    }
    .socialicons a{
        font-size: 13px;
        margin-left: 10px !important;
        margin-right: 0px !important;
        padding: 10px !important;
        border-radius: 50%;
        margin-top: 0px;
    }
}