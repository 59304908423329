.collapseBox {
    padding: 10px 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 6px -2px rgb(107 105 105);
}
.boxcontainent {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 20px;
    background: #fff;
    border-radius: 5px;
}
.departndate {
    padding: 11px;
    padding-left: 30px;
    background: #EC5E71;
    color: #fff;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.tlt-depart {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: .5px;
}
.date-depart {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .5px;
    margin-top: -4px;
}
.depart-tlt {
    padding: 15px;
}
.btn-collapse {
    /* padding-top: 10px; */
}
.departcode {
    font-size: 20px;
    font-weight: 700;
}
.departcodedetails {
}
.departcodedetails span {
    font-size: 13px;
    font-weight: 600;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #ccc;
}
.btnCollapsedsect {
    align-self: center;
}
.btn-collapse button {
    padding: 0px !important;
    font-size: 14px!important;
    text-transform: capitalize!important;
    border: none !important;
    font-weight: 600;
}
.btn-collapse button:hover{
    padding: 0px !important;
    background-color: transparent !important;
    border: none !important;

}
.imgdepartbooking {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.departflightname {
    font-size: 13px;
    font-weight: 600;
}
.departflightnumber {
    font-size: 13px;
    font-weight: 500;
}
.departimgminifly {
    margin-right: 20px;
}
.flexset-depart-inside {
    display: flex;
    align-items: center;
    text-align: center;
}
.definedepartpwidth {
    flex-basis: 50%;
}
.departarrowbox {
    flex-basis: 30%;
}
.departdefineroundtripwidth {
    width: 50%;
}
.departflightminitime {
    font-size: 18px;
    font-weight: 700;
}
.departflightminidata {
    font-size: 10px;
    font-weight: 800;
}
.departflightpara p {
    font-size: 13px;
    font-weight: 500;
    color: #999;
}
.CollapseDetails {
    background: #fff;
    padding: 15px;
    box-shadow: 0px 1px 5px -1px rgb(0 0 0 / 40%);
}
.alignmentBox {
    align-self: center;
}
span.boldtxt {
    font-size: 12px;
    font-weight: 500;
}
span.txtlight {
    font-size: 13px;
    font-weight: 600;
}
.baggagedepart{
    padding: 5px 0;
    border-top: 1.8px solid #ccc;
}
/********traveller details*************/
.inputboxdetails {

}
.inputFieldBox {
    display: flex;
    margin-bottom: 2px;
}
.tlt-traveller {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}
.Subtlt-traveller {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
}
.tlt-input-traveller {
    font-size: 12.5px;
    margin-bottom: 0px;
    font-weight: 500;
    color: #000;
}
.pd-left {
    margin-left: 12px!important;
}
.travellerdetailsBox {
    background: #fff;
    box-shadow: none;
    border-radius: 0px;
    padding: 15px 30px;
}
label.labelcheckradio {
    margin: 0!important;
}
.inputboxdetails {
    width: 100%;
}
.inputdetails {
    width: 80%;
    margin-left: 5px!important;
}
.countrycode {
    width: 150px!important;
}
/***********contact details************/
.tlt-contactdetails {
    font-size: 20px;
    font-weight: 600;
    padding: 16px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}
/*******select seat*************/
.tltseat {
    font-size: 20px;
    font-weight: 500;
}
.tltseat {
    font-size: 20px;
    font-weight: 500;
    padding: 8px 15px;
    border-bottom: 1px solid #ccc;
}
.prst-data {
    display: flex;
    align-items: center;
}
.flightsetseat {
    flex-basis: 80%;
}
.tlt-fly {
    font-size: 18px;
    font-weight: 600;
}
.subtlt-fly {
    font-size: 15px;
    font-weight: 500;
}
.seat-selectionBox {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 5px -1px rgb(0 0 0 / 40%);
}
/****ssr details*****/
.ssr-tlt {
    padding: 15px 15px;
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
}
.tlt-ssr-mini {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
}
.ssrdetailsbox {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 5px -1px rgb(0 0 0 / 40%);
    padding-bottom: 10px;
}
.tablesetssrdetails {
    background: #EC5E71;
}
.tablesetssrdetails th{
    color: #fff !important;
}
.dropdowntxtbox {
    display: flex;
}
.labeltxt-mini {
    font-size: 12px;
    font-weight: 500;
}
.lfield {
    width: 100%;
}
.rfield {
    width: 100%;
    margin-bottom: 12px;
}
.dropdownssrinput {
    width: 100%;
}
.tltssrbox {
 font-size: 15px;
 font-weight: 600;
}
.labeltxt {
    font-size: 16px;
    font-weight: 600;
}
.tlt-txt {
    font-size: 16px;
    font-weight: 600;
}
.tlt-txt-stag {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 500;
    color: red;
}
.dropdownssrinput {
    width: 100%;
}
button.seatbtnpopup {
    font-size: 12px;
    font-weight: 600;
}
.dropdowntxtbox {
    margin-top: 15px;
    margin-bottom: 10px;
}
.flexBoxdiv {
    display: flex;
    align-items: center;
}
.tableContainerbox {
}
th.thcell {
    text-align: -webkit-center;
    font-weight: 600;
    font-size: 12px;
    padding: 3px 10px;
    border: 1px solid #ccc;
    text-transform: uppercase;
}
td.tdcell {
    text-align: -webkit-center;
    font-size: 14px;
    padding: 4px 10px;
    border: 1px solid #ccc;
    text-transform: capitalize;

}
/********gst business ***********/
.gsttxt {
    padding: 15px;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #ccc;
    margin-bottom: 5px;
}
.notedtxt {
    font-size: 14px;
    font-weight: 500;
    color: brown;
    margin-bottom: 5px;
}
.inputgsttlt{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
}
.gstinputfield input {
    /* padding: 8px!important; */
}
.gstRowblock {
    /* padding: 15px 15px 0px 15px; */
}
.gstbox {
    padding-bottom: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 5px -1px rgb(0 0 0 / 40%);
}

.seatCol {
    width: 100%;
    height: 40vh;
    background-color: #ccc;
}
.seatCol1 {
    width: 100%;
    height: 40vh;
    background-color: antiquewhite;
}
.seatCol2 {
    width: 100%;
    height: 40vh;
    background-color: yellowgreen;
}
.dailogBox-tlt {
    display: flex;
    align-items: center;
}
.main-tlt {
    font-size: 18px;
    font-weight: 500;
    flex-basis: 20%;
}
/* .popupboxseatset {
    min-width: 1209px!important;
} */
.dialogimgminifly {
    margin-right: 7px;
}
.dialogimgminifly img {
    width: 39px;
}
.dailogflightname {
    font-size: 14px;
    font-weight: 500;
}
.dailogflightnumber {
    font-size: 14px;
    font-weight: 500;
}
.flightorientation {
    font-size: 14px;
    font-weight: 500;
    color: #676464;
}
.zerogapcoldailog {
    padding: 0!important;
}
.passenger-seat {
    background: aliceblue;
}
.tblrow {
    display: flex;
    align-items: center;
    width: 100%;
}
.tblitems {
    flex-basis: 50%;
    padding: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}
.tblcolitems {
    flex: 50%;
    padding: 6px;
    font-size: 14px;
    color: #5a5858;
}
.tblrowitems {
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #ccc;
}
.tblrowitemsactive {
    background-color: #3c9c408f;
}
.tblrow {
    background: antiquewhite;
    border-radius: 5px;
}
.passenger-seat {
    margin-bottom: 15px;
}
.tlt-para {
    font-size: 13px;
    font-weight: 500;
    color: red;
}
.notify-para p {
    font-size: 13px;
    color: #000;
    text-align: justify;
    letter-spacing: -0.1px;
    word-spacing: -1px;
    word-break: break-word;
}
.seatstatus {
    display: flex;
    text-align: center;
    flex: auto;
}
.close-ico {
    width: 30px;
    height: 25px;
    background: #ccc;
    color: #fff;
    margin: 5px 5px 5px 0;
    border-radius: 5px;
}
.check-ico {
    width: 30px;
    height: 25px;
    background: #28a745;
    color: #fff;
    margin: 5px;
    border-radius: 5px;
}
.selectcolor {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    margin-right: 5px;
}
/* .seatstatus {
    margin-bottom: 15px;
} */
.seatlayout {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.occupiedseat {
    width: 40px;
    height: 40px;
    text-align: center;
    align-items: center;
    border: 1px solid #ccc;
    font-size: 19px;
    padding: 5px;
    margin: 3px;
    color: #000;
    background: #ccc;
}
.selectedseat {
    width: 40px;
    height: 40px;
    text-align: center;
    align-items: center;
    border: 1px solid #ccc;
    font-size: 19px;
    padding: 5px;
    margin: 3px;
    background-color: #28a745;
    color: #fff;
}
.availableseat {
    width: 40px;
    height: 40px;
    text-align: center;
    align-items: center;
    border: 1px solid #ccc;
    font-size: 12px;
    padding: 9px;
    font-weight: 500;
    margin: 3px;
    background: beige;
    color: #000;
    cursor: pointer;
}

.columBoxRow {
    margin-left: 10%!important;
}
.coulmSeatview {
    margin: 0px;
}
.flightorientation span svg {
    font-size: 30px!important;
}
.tabs button {
    padding: 5px;
    font-size: 15px;
    text-align: left!important;
}
button.tabsset {
    font-size: 15px;
    font-weight: 500;
    text-align: left!important;
    margin-bottom: 3px;
    background: #ccc;
    border-radius: 5px;
}
.paneldivbox {
    flex-basis: 80%;
    padding-left: 45px;
}
.boxoverlay {
    position: relative;
    width: 100%;
}
.overlayposotion {
    position: absolute;
    background: #f2f2f2;
    padding: 3px 20px;
    border-radius: 30px;
    top: 2px;
    color: red;
    left: 30%;
    font-size: 10px;
    font-weight: 500;
}
/****************/

.setsector {
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color: #EC5E71;
    margin-bottom: 8px;
    margin-left: -15px;
    padding: 7px 19px;
    color: #fff;
    background: #EC5E71;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
.datesethtd {
    margin-left: 6px;
    color: #808080;
}
.arrowsect img {
    width: 60px;
    padding: 8px;
}
.tltheadbx {
    font-size: 14px;
    font-weight: 500;
}
.itextbox {
    width: 100%;
    padding: 10px;
    background-color: #999;
}
.paxtltnm {
    font-size: 14px;
    font-weight: 500;

}
.txtheadpaxrow {
    background: #fc9e15;
    color: #000;
    padding: 5px 0px;
}
.dtatrowdata {
    padding: 8px 0;
}
.meal-list{
    width: 500px !important;
    white-space: normal !important;
    font-size: 16px !important;
}

.Main-content-header {
    z-index: 9 !important;
}

.MuiMenu-list li{
    display: flex !important;
    padding: 6px 16px !important;
}

.travellerdetailsBox .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 0px !important;
    line-height: 23px;
    border: 1px solid #ddd !important;
    background: #fff;
}

textarea {
    border: 1px solid #ddd !important;
}

.travellerdetailsBox .MuiSelect-select {
    padding: 7px !important;
    font-size: 12px;
}


.switch-offlinemode{
    margin: -5px;
    margin-right: 0px !important;
}

.label-offlinemode{
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 600;
}

/* New Ui */

.rightBoxContainer{
    margin: 20px 0px;
    background: #fff;
    padding: 15px 15px 0px;
    border-radius: 6px;
}

.depart-tlt-top  .departcode {
    font-size: 18px;
    font-weight: 700;
}

.summarycontainer{
    padding: 0px 15px 10px;
}
.summarycontainer .arrowbox {
    width: 150px;
    margin: 3px;
    text-align: center;
}

.summarycontainer .define-stop {
    font-size: 11px;
    font-weight: 500;
    color: #000;
    padding-bottom: 8px;
}
.baggageBox{
    display: flex;
}
.items-head {
    margin-right: 20px;
}

.summary-date {
    font-size: 12px;
    font-weight: 600;
}
.summary-time {
    font-size: 12px;
    font-weight: 800;
}


.inputBoxgst .gstinputfield{
    width: 100%;
}

.mealBtn button{
    display: block;
    padding: 5px !important;
}

.tltname-meal{
    margin-bottom: 5px;
    font-size: 14px;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
}

.sectorToggleBtn{
    background: aliceblue !important;
    margin-bottom: 10px !important;
    width: max-content;
    margin-right: 15px;
}

.sectorToggleBtn:hover{
    color: #fff !important;
    background: #EC5E71 !important;
}
.sectorToggleBtnActive{
    color: #fff !important;
    background: #EC5E71 !important;
    margin-bottom: 10px !important;
    width: max-content;
    margin-right: 15px;
}

.mealBox{
    background: #f8f9fa;
    padding: 10px 20px;
}

.inputboxremark input{
    height: 50px !important;
}

.Mui-error{
    margin-left: 0px;
}

.define-timecalc {
    font-size: 11px;
    font-weight: 500;
    padding-top: 8px;
}
.arrow-img {
    position: relative;
}
.arrow-line{
    border-bottom: 1px dotted #6c757d;
    display: block;
    height: 1px;
    width: 45%;
    margin: auto;
}

.baggage-img img {
    width: 75%;
    padding: 5px;
}
.meal-img img{
    width: 65%;
}
.baggage-row {
    background: #fff;
    padding: 10px 0px;
    margin-bottom: 10px;
}
.baggage-heading li{
    font-size: 14px;
    padding: 0px;
    list-style: none;
}
.meal-price {
    font-size: 14px;
    font-weight: 700;
    display: block;
}

.baggage-btn .MuiButton-root {
    min-width: 30px;
}
.add-on-head{
    padding: 10px 0px;
    background: #fff;
    margin-bottom: 16px;
}
.ttl-cityname{
    font-size: 18px;
    font-weight: 500;
}
.meal-count{
    font-size: 13px;
}

.container-approval .container{
    border: 1px solid #ddd;
    height: 200px;
}

.message {
	display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    height: 180px;
    justify-content: center;
    font-family: monospace;
    font-size: 14px;
}

.message__text {
	margin-top: 12px;
	text-align: center;
}

.shortcut {
	display: flex;
	align-items: center;
}

.shortcut__key {
	padding: 6px;
	border: 2px solid var(--color-light);
	border-radius: 6px;
}

.shortcut__sign {
	margin: 0 6px;
}

.image {
	max-width: 100%;
	max-height: 100%;
    height: 200px;
    object-fit: contain;
}

.hidden {
	display: none;
}

.btn-remove-img, .btn-remove-img:hover{
    position: absolute;
    right: 5px;
    background: transparent;
    border: none;
    color: #000;
    padding: 0px;
    top: -12px;
}