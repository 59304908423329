@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #F2F5F9 !important;
  overflow-x: hidden;
}
a {
  padding: 0;
  margin: 0;
  color: #1A1A1A !important;
}
a:hover{
  text-decoration: none !important;
  color: #1A1A1A;
}

img{
  width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.calendarBox-airpassenger {
  padding: 15px;
  width: 92%;
  height: 60%;
  margin: 1% auto;
  border-radius: 10px;
}


.calendarBox {
  padding: 15px;
  width: 60%;
  height: 60%;
  margin: 1% auto;
  border-radius: 10px;
}
.fc .fc-daygrid-day-frame {
  position: relative;
  min-height: 103px;
  padding: 10px;
  font-weight: 500;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: aliceblue;
}
.daterate {
  width: 100%;
  font-size: 13px;
  color: #000;
}
.daterate label {
  margin: 0;
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 14px;
  color: #4caf50;
  cursor: pointer;
}
.daterate span {
  margin: 0;
  width: 100%;
  display: block;
  text-align: left;
  font-size: 13px;
  color: #585454;
}
.fc-h-event {
  display: block;
  border: none;
  background-color: transparent;
}
table.fc-col-header tr {
  background: #DC354B;
}
a.fc-col-header-cell-cushion {
  font-size: 14px;
  font-weight: 500;
  color: #fff!important;
}
.fc .fc-toolbar-title {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  color: #DC354B;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 8px;
}
.fc {
  width: 100%;
}
.fc .fc-button-primary {
  color: #fff;
  background: #DC354B;
}
table.fc-col-header tr th {
  border: 1px solid #DC354B;
}
.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.tableico {
  margin-top: 5px;
  margin-bottom: 3px;
}

.tooltiprow {
  width: 100%;
}
.tooltipdtlsim{
  display: flex;
  align-items: flex-start;
}
.tooltipdtlsim label {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  margin-top: 7px;
  padding-left: 10px;

}
.tooltipdtls label{
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  margin: 0;
}
span.pricemini {
  font-size: 12px;
}
.fc-daygrid-dot-event:hover, .fc-daygrid-dot-event.fc-event-mirror {
  background: transparent;
}
.fc .fc-scrollgrid {
  border-collapse: separate;
  border-right-width: 0;
  border-bottom-width: 0;
}
.fc .fc-scroller-liquid-absolute {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.fc .fc-scrollgrid-section-body table, .fc .fc-scrollgrid-section-footer table {
  border-bottom-style: hidden;
  background: #fff;
}
.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      -webkit-animation: App-logo-spin infinite 20s linear;
              animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #f2f2f2;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  .flightsearchpagebg {
    background: #f2f2f2;
    width: 100%;
    height: 100%;
  }
  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
body{
    font-family: "Roboto", sans-serif !important;
    color: #1A1A1A;
}
ul, ul li{
    list-style: none;
    padding: 0;
}
.bg-white{
    background: #FFFFFF;
}

.bg-blue-grey{
    background: #F2F5F9;
}
.text-light-grey{
    color: #525252;
}

.font-go-back{
    display: none;
}
.font-large{
    font-size: 18px;
}
.font-large-bold{
    font-size: 18px;
    font-weight: 700;
}
.font-medium{
    font-size: 16px;
}
.font-medium-bold{
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
}
.font-small{
    font-size: 14px;
}
.font-small-bold{
    font-size: 14px;
    font-weight: 700;
}
.font-11{
    font-size: 11px;
}
.font-11-bold{
    font-size: 11px;
    font-weight: 700;
}
.font-12{
    font-size: 12px;
}
.font-12-bold{
    font-size: 12px;
    font-weight: 700;
}
.font-14{
    font-size: 14px;
}
.font-14-bold{
    font-size: 14px;
    font-weight: 700;
}
.link-button{
    font-size: 11px;
    color: #DC354B !important;
}



.MuiTabs-indicator{
    background-color: #DC354B;
}
.MuiButtonBase-root.Mui-selected {
    color: #DC354B !important;
    border: none !important;
    background: #fff;
    font-weight: 700;
}
.MuiTab-root {
    min-width: 25% !important;
    padding: 0px 15px !important;
    font-weight: 400 ;
    background: #fff;
    font-size: 14px !important;
    text-transform: capitalize !important;
    flex-direction: column !important;
    min-height: 45px !important;
    border: none !important;
    flex-direction: row !important;
}
svg.MuiSvgIcon-root {
    font-size: 14px !important;
    margin-right: 4px;
}

.btn-primary-large{
    width: 141px !important;
    height: 40px;
    color: #fff !important;
    font-size: 16px !important;
    border-radius: 8px !important;
    background-color: #DC354B !important;
    border: 1px solid #DC354B;
    padding: 10px !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
}

.btn-primary-large-border{
    width: 141px !important;
    height: 40px;
    color: #DC354B !important;
    font-size: 16px !important;
    border-radius: 8px !important;
    background-color: #fff !important;
    border: 1px solid #DC354B;
    padding: 10px !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
}

.btn-primary-medium{
    width: 128px !important;
    height: 40px;
    color: #fff !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: #DC354B !important;
    border: 1px solid #DC354B !important;
    padding: 6px !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
}

.btn-primary-medium-border{
    width: 128px !important;
    height: 40px;
    color: #DC354B !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: #fff !important;
    border: 1px solid #DC354B !important;
    padding: 6px !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
}

.btn-primary-small{
    width: -webkit-max-content !important;
    width: max-content !important;
    height: 35px;
    color: #fff !important;
    font-size: 12px !important;
    border-radius: 8px !important;
    background-color: #DC354B !important;
    border: 1px solid #DC354B !important;
    padding: 6px !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
}

.btn-primary-small-border{
    width: -webkit-max-content !important;
    width: max-content !important;
    height: 35px;
    color: #DC354B !important;
    font-size: 12px !important;
    border-radius: 8px !important;
    background-color: #fff !important;
    border: 1px solid #DC354B !important;
    padding: 6px !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
}

.btn-primary-full{
    width: 100% !important;
    height: 40px;
    color: #fff !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: #DC354B !important;
    border: 1px solid #DC354B !important;
    padding: 6px !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
}


.btn-primary-full-border{
    width: 100% !important;
    height: 40px;
    color: #DC354B !important;
    font-size: 14px !important;
    border-radius: 8px !important;
    background-color: #fff !important;
    border: 1px solid #DC354B !important;
    padding: 6px !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
}

.filter-btn{
    width: -webkit-max-content;
    width: max-content;
    height: 32px;
    color: #DC354B !important;
    font-size: 13px !important;
    border-radius: 14px !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    padding: 6px 15px;
    font-weight: 400 !important;
    box-shadow: none !important;
    margin-right: 12px;
    cursor: pointer;
}
.filter-btn.selected{
    background-color: #DC354B !important;
    border: 1px solid #DC354B !important;
    color: #fff !important;
}
.filter-content{
    background-color: #F2F5F9 !important;
}
.filter-content-tab{
    height: 83vh;
}
.filter-content-tab .filter-content-tab-inner{
    height: 83vh;
}

.filter-content-tab .MuiButtonBase-root {
    color: #666666 !important;
    border: 1px;
    border: none !important;
    font-size: 14px !important;
    text-transform: capitalize;
    align-items: center;
    min-height: 20px;
    padding: 10px 15px;
}
.filter-content-tab .MuiButtonBase-root.Mui-selected {
    color: #DC354B !important;
    border: 1px;
    background: #fff !important;
    border: none !important;
    font-size: 14px !important;
    text-transform: capitalize;
    align-items: center;
}

.filter-content-tab .MuiTabs-vertical{
    background: #F2F5F9;
    border-right: 0px !important;
}
.filter-content-tab .MuiTabs-indicator{
    background-color: #fff !important;
}
.sort-content-box .MuiFormControlLabel-root{
   padding: 7px 15px;
   border-bottom: 1px solid #ccc;
   margin: 0px;
   flex-direction: row-reverse;
   justify-content: space-between;
}
.sort-content-box .sort-heading{
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    font-weight: 700;
}
.sort-content-box .MuiFormControlLabel-root span{
    font-size: 14px;
}
.listopt {
    font-size: 15px;
}
.MuiDrawer-root>.MuiPaper-root{
    border-radius: 10px 10px 0px 0px;
}
.optitems .MuiOutlinedInput-root {
    overflow: auto;
    display: flex;
    flex-wrap: nowrap !important;
}

.optitems .MuiInputBase-adornedStart{
    padding-right: 0px !important;
}
.optitems .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-popupIndicator{
    padding: 0px;
}

.optitems .MuiAutocomplete-endAdornment{
    position: relative !important;
    display: flex;
}
.experience-mobile{
    display: none !important;
}
.experience-desktop{
    display: block;
}

.flight-searschi-n-carousel {
    position: relative;
    min-height: calc(100vh - 90px - 200px);
    margin-bottom: 50px;
}
.btn-way {
    width: 110px;
    font-size: 13px!important;
    font-weight: 400!important;
    text-transform: capitalize!important;

}
.contact-form{
    margin-top: 30px !important;
    margin-bottom: 50px !important;
}
.inputField {
    width: 100%;
    padding: 0!important;
    margin: 0!important;
    margin-top: 1px!important;
    margin-bottom: 5px!important;
    border-radius: 8px;
}
.inputField input {
    font-size: 13px!important;
}

.inputFieldLocation {
    width: 100%;
    padding: 0!important;
    margin: 0!important;
    margin-top: 3px!important;
    margin-bottom: 5px!important;
    border-radius: 8px;
}
 .inputFieldLocation input {
    font-size: 13px!important;
}
.inputFieldLocation input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{
    color: #000!important;
    font-size: 13px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}
.inputFieldLocation input:-ms-input-placeholder, textarea:-ms-input-placeholder{
    color: #000!important;
    font-size: 13px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}
.inputFieldLocation input::placeholder ,textarea::placeholder{
    color: #000!important;
    font-size: 13px !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
}
.inputField input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #000!important;
    font-size: 13px !important;
    text-transform: capitalize !important;

    font-weight: 400 !important
}
.inputField input:-ms-input-placeholder, textarea:-ms-input-placeholder {
    color: #000!important;
    font-size: 13px !important;
    text-transform: capitalize !important;

    font-weight: 400 !important
}
.inputField input::placeholder,textarea::placeholder {
    color: #000!important;
    font-size: 13px !important;
    text-transform: capitalize !important;

    font-weight: 400 !important
}
.inputDate input::-webkit-input-placeholder {
    color: #000!important;
}
.inputDate input:-ms-input-placeholder {
    color: #000!important;
}
.inputDate input::placeholder {
    color: #000!important;
}

.lineBar {
    width: 100%;
    padding: 1px;
    margin: 15px 0;
    background-color: #ccc;
}

.btnGroup {
    width: 100%;
}
.inputDate {
    width: 100%;
    padding: 0!important;
    margin: 0!important;
    margin-top: 8px!important;
    margin-bottom: 5px!important;
}
.inputDate input {
    font-size: 13px!important;
}
.inputDate input::-webkit-input-placeholder {
    /* color: #1675b8!important; */
}
.inputDate input:-ms-input-placeholder {
    /* color: #1675b8!important; */
}
.inputDate input::placeholder {
    /* color: #1675b8!important; */
}
.addon-btn {
    font-size: 15px!important;
    font-weight: 400!important;
    width: 75%;
    margin-left: 25%!important;
}
.cntrowswp {
    position: relative;
}
.booking-confirm-modal .modal-dialog{
    box-shadow: 3px 2px 12px 4px #ccc;
    border: none;
    margin-top: 15% !important;
    border-radius: 20px;
}
.nonstopbtn {
    display: inline!important;
}
.nonstop-tlt {
    margin-left: 0px!important;
}

.nonstop-tlt span {
    font-size: 16px;
    color: #000;
    /* font-weight: 700; */
}
.nonstop-tlt .MuiCheckbox-root {
    padding: 9px;
}
.checkbox-tag {
    font-size: 15px;
    /* font-weight: 700; */
    color: #DC354B;
}
.WidthSet {
    max-width: 1000px;
    width: 100%;
}
div#nav-tabpanel-2, div#nav-tabpanel-1, div#nav-tabpanel-0 {
    background: #fff;
}
svg.icoarrow {
    font-size: 40px!important;
}
.tlt-btn-more {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #000;
    font-weight: 600;
    cursor: pointer;
}
.tlt-mini-tag {
    margin: 0;
    font-size: 12px;
    color: #808080;
}
label.nonstop-tlt span {
    font-size: 12px!important;
    font-weight: 600;
}
.option-items {
    padding-left: 40px;
}

.ClassSelector {
    width: 100%;
    font-size: 17px!important;
    background: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    padding: 6px 0px;
    font-family: "Roboto", sans-serif !important;
}
.items-list {
   border-bottom: .5px solid #ccc!important;
   font-size: 14px!important;
   padding: 5px!important;
}
.ClassSelector>div {
    width: 100%;
    font-size: 11px!important;
    background: transparent;
    border: none;
    outline: none;
    font-weight: 500px;
    padding: 0px 25px !important;
    font-family: "Roboto", sans-serif !important;
}
.divSet {
    position: relative;
}
.formRow {
    position: relative;
}

svg.times-icon {
    position: absolute;
    top: 36px;
    right: -26px;
    cursor: pointer;
}
.tabsPanelContainer {
    padding-right: 15px!important;
}
.tabsPanelbdr {
    /* box-shadow: 3px 2px 4px 1px #e9ecef; */
    border-top: 1px solid #e9ecef;
    border-bottom: 1px solid #e9ecef;
}
.text-red {
    color: #dc3545!important;
}
.prstColl {
    position: relative;
    padding-right: 20px!important;
}
.prstColr {
    position: relative;
    padding-left: 20px!important;
}
svg.locationiconl {
    position: absolute!important;
    top: 40px;
    left: 13px
}
svg.locationiconr {
    position: absolute;
    top: 40px;
    left: 25px;
}
.gpgutter {
    margin-top: -10px;
}

.swipebox_multicity {
    position: absolute;
    top: 30px;
    left: 32%;
    z-index: 999;
    cursor: pointer;
}
.swipebox_modify {
    position: absolute;
    top: 30px;
    left: 49%;
    z-index: 999;
    cursor: pointer;
}
.swipebox_modify_multicity {
    position: absolute;
    top: 30px;
    left: 32%;
    z-index: 999;
    cursor: pointer;
}
.swipebox_modify_calenderfare{
    position: absolute;
    top: 30px;
    left: 49%;
    z-index: 999;
    cursor: pointer;
}

.sports-filter{
    display: flex;
    flex-direction: row;
}
.sports-filter span{
    margin-left: 10px;
}

.filter-btn-group{
    display: flex;
    flex-wrap: wrap;
}
.filter-btn-group a{
    margin-bottom: 5px;
    margin-top: 5px;
}
.request-call-box{
    margin-bottom: 0px !important;
}
.request-call-box span{
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0px !important;
}
.request-call-box label{
    margin-bottom: 0px !important;
}
.BOOKING_CONFIRMED{
    padding: 3px 15px;
    background: #28a745;
    border-radius: 9px;
    font-weight: 500;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    font-size: 10px;
    color: #fff;
}


.BOOKING_INCOMPLETE, .BOOKING_INCOMPLETE, .BOOKING_VOUCHERED , .BOOKING_PENDING, .BOOKING_FAILED, .BOOKING_INPROGRESS, .CANCELLATION_INPROGRESS  {
    padding: 3px 15px;
    background: #3c8dbc;
    border-radius: 9px;
    font-weight: 500;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    font-size: 10px;
    color: #fff;
}


.BOOKING_CANCELLED{
    padding: 3px 15px;
    background: #dd4b39;
    border-radius: 9px;
    font-weight: 500;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    font-size: 10px;
    color: #fff;
}

.BOOKING_HOLD{
    padding: 3px 15px;
    background: #00c0ef;
    border-radius: 9px;
    font-weight: 500;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    font-size: 10px;
    color: #fff;
}
.agree-modal svg.MuiSvgIcon-root {
    font-size: 22px !important;
    margin-right: 4px;
}

.agree-modal .MuiTypography-root{
    font-size: 16px;
}

.search-wpr{
    display: flex;
    padding: 10px 15px !important;
    border-radius: 20px;
    background: #F2F5F9;
}

.BOOKING_ERROR{
    padding: 3px 15px;
    background: #28a745;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
}
.p-font14{
    font-size: 12px;
}
.p-font14-rd{
    font-size: 12px;
}

.Slider-deales-xs {
    padding-top: 2%;
    padding-bottom: 2%;
}
/* MUI Button Override CSS */



.clear {
    color: rgba(0, 0, 0, 0.87) !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background-color: #e0e0e0 !important; 
}
.MuiButton-sizeSmall{
    text-transform: uppercase;
    min-width: 50px;
    padding: 4px 14px !important;
   
}

button.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.MuiButton-outlined{
    border-color:  #ccc !important;
}

.MuiButton-outlined:hover{
    border: 1px solid #ccc !important;
}

.MuiCardActionArea-root {
    display: block !important;
}

.btnGroup .MuiButtonBase-root{
    padding: 3px 9px !important;
    border-radius: 4px !important;
    border: 1px solid #ccc !important;
}

.MuiButtonGroup-groupedOutlinedPrimary:not(:last-of-type) {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right-color: transparent !important;
}

.MuiButtonGroup-groupedOutlinedPrimary:not(:last-child) {
    border-right-color: transparent !important;
}

.MuiButtonGroup-groupedOutlinedPrimary:not(:first-of-type) {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0 !important;
    margin-left: -1px !important;
}

.btnGroup .MuiListItem-root{
    display: flex !important;
}

.sliderrange{
    height: 2px !important;
}

.MuiSlider-thumb{
    width: 12px !important;
    height: 12px !important;
    border-radius: 2px !important;
}
.items-list:hover {
    background-color: #DC354B!important;
    color: #fff;
}

.makeStyles-formControl-4 {
    margin-top: 16px !important;
    margin-bottom: 8px !important;
}

fieldset{
    border: none !important;
    border-width: none !important;
    border-color: none !important;
}
.flighttabsdetails-link {
    padding: 0px 15px !important;
    font-size: 10px !important;
    height: 22px !important;
    min-height: 30px !important;
}
.MuiOutlinedInput-root {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.MuiOutlinedInput-root:hover {
    border: 1px solid #DC354B !important;
}

 .MuiAutocomplete-input {
    padding: 2.5px 38px 2.5px 1px !important;
    padding-left: 5px !important;
}

.MuiAutocomplete-endAdornment {
    right: 9px;
    top: auto !important;
}

.MuiOutlinedInput-root{
    width: 100% !important;
    height: 40px !important;
    padding: 6px 8px !important;
    font-size: 12px !important;
}
/* 
.MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 5px !important;
} */

.modifydata .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 0px !important;
}

.timingroup .MuiButton-sizeSmall {
    padding: 4px 9px !important;
}

.Mui-checked {
    /* color: #fc9e15 !important; */
}
.MuiPagination-ul{
    justify-content: center;
}
.MuiPagination-ul .Mui-disabled{
    background-color: #f2f2f2 !important;
}
.MuiPagination-ul .MuiPaginationItem-root{
    border-radius: 50% !important;
    transition: none !important;
}
.MuiPaginationItem-page:hover{
    border-radius: 50% !important;
    transition: none !important;
}
.MuiPagination-ul .Mui-selected {
    border-radius: 50% !important;
    transition: none !important;
}
.CircularProgress{
    justify-content: center;
}
.agecontrol select{
    padding: 23px 12px 5px !important;
}
input[type="file"] {
    padding: 5px 10px 14px!important;
}
.PrivateSwitchBase-root{
	background-color: transparent !important;
}
.MuiButtonGroup-grouped {
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
}

table {
	display: table !important;
	visibility: visible !important;
}

.MuiMenu-list li{
    display: flex !important;
    padding: 6px 16px !important;
    font-size: 16px !important;
}

/* .MuiMenu-list li.Mui-selected{
    background-color: rgb(254 247 248) !important;
} */

.banner-bg-image{
    height: 550px;
}

.icon-time{
    color: #000;
}

.share-checkbox{
    padding: 0px 15px !important;
}


.gst_width{
    width: 750px !important;
}

.colspacemini {
    padding: 0!important;
}

.modifydata .MuiTabs-flexContainer {
    width: 50% !important;
    background: #fff;
}

.r-table-container{
    background: transparent !important;
    box-shadow: none !important;
    overflow-x: visible !important;
    overflow-x: initial !important;
}


.MuiFormHelperText-root.Mui-error, .MuiFormHelperText-root, .text-danger{
    margin: 0 !important;
    font-size: 11px !important;
    color: #dc3545!important;
}
/* Responsive CSS Ends */

.react-multiple-carousel__arrow {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 21px;
    height: 20px;
    padding: 0;
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%);
    cursor: pointer;
    color: #DC354B;
    z-index: 1 !important;
    border: none;
    outline: none;
    background: #DC354B !important;
}

.MuiSnackbar-root {
    z-index: 99999 !important;
}

/* new UI for ITWgo */

.light {
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 50%;
       bottom: 295px;
    position: absolute;
    z-index: 10;
    left: 20px;
    -webkit-animation: light 400ms ease-in-out 0s infinite alternate;
            animation: light 400ms ease-in-out 0s infinite alternate;
  }
  .cloud {
    position: absolute;
    top: 13px;
    right: -500px;
    opacity: .8;
    animation: cloud 30s linear 0s infinite reverse;
    z-index: 9;
  }

  .cloud img{
      width: 30px;
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg);
  }
  @-webkit-keyframes light {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  @keyframes light {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
  @-webkit-keyframes cloud {
    from {right:0px;}
    to {right: 88%;}
  }
  @keyframes cloud {
    from {right:0px;}
    to {right: 88%;}
  }

  .progess-wrp{
      margin-top: 0%;
      background-color: #ddd;
  }
  .flight-progress-wrp .MuiLinearProgress-root {
        margin: 6px 0px !important;
        height: 2px !important;
        background-image: linear-gradient(90deg, transparent, transparent 50%, #ccc 50%, #ccc 100%), linear-gradient(90deg, #ddd, #ddd);
        background-size: 10px 2px, 100% 2px;
        border: none;

    }
   .flight-progress-wrp .MuiLinearProgress-bar{
       height: 2px !important;
       background-image: linear-gradient(90deg, transparent, transparent 50%, #fff 50%, #fff 100%), linear-gradient(90deg, #ddd, #ddd);
       background-size: 10px 2px, 100% 2px;
   }

    .dashed-line{
        display: block;
        border: 0.4px solid #ccc;
        background-color: #808080;
    }
    .share-cross-btn svg{
        font-size: 17px !important;
    }
    /* .reset-btn{
        text-align: right;
        border-bottom: 0.3px solid #444;
    } */

    .reset-btn button , .reset-btn button:hover{
        color: #fff;
        font-size: 12px;
        padding: 0px 0px 4px;
        font-weight: 600;
    }

    .groupfareG{
        width: 20px;
    }

    .coin-img {
        width: 25px;
    }

    .text-banner1{
        color: #DC354B;
        font-family: "Roboto", sans-serif !important;
        font-size: 20px;
        margin-top: 10px;
    }

    .text-banner2{
        font-size: 14px;
        margin: 0;
    }

    .sports-container{
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .sports-container-inner{
        padding-top: 15px !important;
    }

    .sports-contries{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .container-not-found{
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 80px 0px;
    }
    .experience-mobile .sports-img {
        width: 48%;
        grid-gap: 10px;
        gap: 10px;
    }
    .experience-mobile .sports-img img{
        border-radius: 16px;
        margin-bottom: 15px;
    }
    .experience-desktop .sports-img{
        padding: 0px 15px 15px 0px;
    }
    .experience-desktop .sports-img img{
        width: 100%;
        border-radius: 16px;
    }
    .card-img{
        padding-bottom: 20px;
    }

    .card-img img{
        width: 328px;
        height: 180px;
        border-radius: 16px;
    }
    .card-img p{
        margin-bottom: 0px;
        text-align: justify;
    }

    .event-tag{
        position: absolute;
        left: 22px;
        top: 30px;
        color: #fff;
        font-weight: 700;
        background: #dc354b;
        border-radius: 6px;
        padding: 2px 5px;
        font-size: 10px !important;
    }
    .twelve{
        position: relative;
        width: -webkit-max-content;
        width: max-content;
    }
    .dealwrapper { max-width: 100%; background: #ffffff; position: relative;}
    .dealwrapper .list-group-item{
        background-color: #f2f5f9;
        border: none;
        margin-bottom: 15px;
        border-radius: 12px !important;
    }
    .speech-box {
        position: relative;
        background: #f3e463;
        width: 345px;
        height: 170px;
        margin: -15px;
        transition: .2s linear;
        -webkit-clip-path: polygon(12% 12%, 70% 10%, 68% 45%, 44% 44%, 48% 61%, 30% 44%, 7% 45%);
      }
      .speech-box:hover {
        width: 340px;
        height: 170px;
      }
      .speech-box h1 {
        position: absolute;
        display: inline-block;
        top: 30px;
        left: 50px;
        font-size: 14px;
        transition: all .2s linear;
      }
      .speech-box:hover h1 {
        font-size: 13px;
      }

      .list-group-inner{
        margin-top: -65px;
      }

      .animate-charcter
        {
        text-transform: uppercase;
        background-image: linear-gradient(
            -225deg,
            hsl(253, 50%, 14%) 0%,
            #f435d1 29%,
            #ff1361 55%,
            #000 100%
        );
        background-size: auto auto;
        background-clip: border-box;
        background-size: 200% auto;
        color: #fff;
        background-clip: text;
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-animation: textclip 2s linear infinite;
                animation: textclip 2s linear infinite;
        display: inline-block;
            font-size: 10px;
        }

        @-webkit-keyframes textclip {
        to {
            background-position: 200% center;
        }
        }

        @keyframes textclip {
        to {
            background-position: 200% center;
        }
        }

    .sliderrange{
        color: #DC354B;
    }
    
    .breadcrumb-custom{
        padding: 15px;
        background-color: #fff;
    }

    .breadcrumb-custom nav{
        max-width: 1220px;
        font-size: 12.5px;
        margin: auto;
    }
    .breadcrumb-custom nav svg{
        font-size: 15px !important;
    }
    .breadcrumb-custom nav li a{
        color: #878ba1 !important;
        cursor: pointer;
    }
    .breadcrumb-custom nav li p{
        font-size: 12.5px;
    }
    .title-2{
        width: 100%;
    }
    .title-2 h1{
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 10px;
        color: rgb(51, 51, 51);
    }

    .Mui-checked{
        color: #DC354B !important;
    }

    textarea{
        width: 100%;
        padding: 10px;
        font-size: 15px !important;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
    

/* Responsive CSS Starts */

@media (max-width: 1370px){
    button.rc-menu-button.upadtedmenuitems {
        font-size: 15px;
    }
    .balanced-view {
        font-size: 12px !important;
    }
    .dropdown-profile {
        width: 150px;
    }
    button.profile {
        font-size: 11px !important;
    }

}

@media (max-width: 1300px){

    .slick-next {
        right: -20px !important;
    }

    .slick-prev {
        left: -30px !important;
    }

    .timing-item {
        font-size: 12px!important;
        padding: 10px !important;
    }
}

/* Responsive CSS Starts */
@media (min-width: 1500px) {
    .banner-text {
        padding: 4% 0px 0px !important;
    }
}

@media (max-width: 1370px){
    button.rc-menu-button.upadtedmenuitems {
        font-size: 15px;
    }

    .balanced-view {
        font-size: 12px !important;
    }

    .dropdown-profile {
        width: 150px;
    }
    button.profile {
        font-size: 11px !important;
    }

}

@media (max-width: 1300px){


    .slick-next {
        right: -20px !important;
    }

    .slick-prev {
        left: -30px !important;
    }

    .timing-item {
        font-size: 12px!important;
        padding: 10px !important;
    }
}
@media (min-width: 2000px) {
    
}

@media (max-width: 1199px){
   
    .viewinputhotel{
        font-size: 13px !important; 
    }
    .supplier-txt{
        font-size: 10px !important;
    }
    button.btnmarkup {
        margin-top: 10px;
    }
    .slick-track {
        -webkit-transform: translate3d(0, 0, 0) !important;
                transform: translate3d(0, 0, 0) !important;
    }
    .auth-wrapper {
        max-width: 1400px !important;
    }
    .box-prst {
        width: 100% !important;
        height: 142px !important;
    }
    .ico-box, .ico-box2 {
        width: 100% !important;
    }
    label.itemsradio span {
        font-size: 10px !important;
    }
    .share-cbx{
        width: auto !important;
    }

    .available-seats {
        font-size: 8px !important;
        display: block !important;
        width: 83px !important;
    }
    .timing-item {
        font-size: 12px!important;
        padding: 10px !important;
    }
    .timing-item {
        font-size: 11px!important;
        padding: 5px !important;
    }
    .share-cbx-round {
        margin-top: 22px !important;
    }
    .flight_details_btn {
        width: 100% !important;
    }
    .icon-flight {
        width: 40px !important;
    }
}

@media (max-width: 1024px){
    .share-checkbox {
        padding: 2px !important;
    }
   
    .btn-booking-roundtrip {
        min-width: 90px;
        font-size: 13px !important;
        margin-left: 25px!important;
    }
    .white-bg-xs {
        background-color: #fff;
    }
    .btnview {
        font-size: 10px!important;
    }
    .flexset {
        padding: 0 0px !important;
    }
    .flightsearch-box {
        display: block !important;
    }
    svg.flighticonmodify {
        font-size: 18px !important;
    }
    .timing-item {
        font-size: 10px!important;
        padding: 6px !important;
    }
    .timing-item {
        font-size: 9px!important;
    }
    .swipebox_modify {
        top: 46% !important;
    }
    .supplier-txt{
        display: block;
    }
}


@media (min-width: 1300px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl , .MuiContainer-maxWidthLg{
        max-width: 1240px !important;
    } 
    .showFixedFilter {
        max-width: 265px !important;
    }
}

@media (max-width: 1250px){
    .showFixedFilter {
        max-width: 260px !important;
    }
}
@media (max-width: 1250px) and (min-width: 787px){
  
    .container, .container-lg, .container-md, .container-sm, .container-xl , .MuiContainer-maxWidthLg, .container-fluid{
        max-width: 100% !important;
    } 
    .btnSend{
        width: -webkit-max-content !important;
        width: max-content !important;
    }
    nav.NavbarHeader.navbar.navbar-expand-lg.navbar-light {
        padding: 0.8rem 0rem !important;
    }
    .special-offer-right .flightmininame {
        width: 75px !important;
    }
    .card-img img{
        width: 100%;
    }
    .card-img img {
        width: 100%;
        height: 180px;
        border-radius: 16px;
    }
}

@media (max-width: 1150px) {
    .showFixedFilter {
        max-width: 240px !important;
    }
}
@media (max-width: 900px){
    .sort-by-desktop, .filter-desktop{
        display: none !important;
    }
    .card-img img{
        width: 98%;
    }
    .show-tooltip-mob{
        display: block;
    }
    .show-tooltip-desk{
        display: none;
    }
    .font-go-back{
        color: #384152 !important;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        align-items: center;
        display: flex !important;
        margin-top: 10px;
        margin-left: 5px;
    }
}
@media (max-width: 786px){
    .booking-confirm-modal .modal-dialog{
        margin-top: 45% !important;
    }
    .contact-form{
        margin-top: 0px !important;
        margin-bottom: 20px !important;
    }
    .sports-filter{
        flex-direction: column;
    }
    .sports-filter span{
        margin-left: 0px;
    }

    .experience-desktop .sports-img img{
        width: 100%;
        border-radius: 16px;
        margin-bottom: 15px;
    }
    .sports-container{
        margin-top: 0px;
    }
    .row {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
    .tlt-range {
        flex-basis: 100%;
    }
    .activity-list .card-details h1 {
        font-size: 16px;
    }
    .MuiMenu-list li {
        display: flex !important;
        padding: 5px 20px !important;
        font-size: 14px !important;
        min-height: 30px !important;
    }
    
    svg.MuiSvgIcon-root {
        font-size: 16px !important;
    }
    .white-bg-xs{
        padding: 0px !important;
    }
    .box-prst {
        width: 100% !important;
        height: auto !important;
    }
    button.tabTilte {
        font-size: 11px !important;
    }

    .arrowtabinfo img {
        left: 4px !important;
    }
    .closebox {
        top: -12px !important;
        right: -7px !important;
    }

    .setinputBox{
        margin-bottom: 10px;
    }

    .gstinputfield  {
        width: 100%;
    }

    .img-ico {
        height: 95px !important;
    }
    .tltprofilename {
        font-size: 20px !important; 
    }
    span.tltnamegray {
        font-size: 16px !important;
    }
    .paginationbox{
        width: 100% !important;
    }
    .tlt-content-ledger {
        margin-bottom: 0px !important;
        margin-top: 15px;
    }
    thead.dataheadoftable td {
        font-size: 11px !important;
    }
    .calendarBox-airpassenger {
        width: 100% !important;
    }
    .balanced-view{
        margin: 10px;
    }

    .viewbtndetails {
        margin-top: 15px !important;
    }
    .viewdropdown {
        left: 0px !important;
    }

    .header{
        padding:0px !important;
    }
    .scrollingdiv {
        height: 775px !important;
        overflow: hidden !important;
        padding-right: 0 !important;
    }
    
    .prstColr{
        padding-left: 8px!important;
    }

    svg.locationiconr {
        left: 12px;
    }
    div#nav-tabpanel-2 {
        width: auto;
    }

    .swipebox {
        position: absolute;
        top: 46%;
        margin-top: -18px !important;
        z-index: 1;
        right: 0px;
    }

   .Subtlt-traveller {
        padding: 8px 0px !important;
    }
    
    .flightsearch-box {
        display: flex !important;
    }
    .modifysearchbox {
        width: 100% !important;
        margin-top: 21%; 
    }

    .modifybtnhotel{
        margin-bottom: 10px;
    }
    .supplier-txt{
        display: block;
        width: -webkit-max-content;
        width: max-content;
        margin: 15px;
    }
    .linklist-supplier {
        margin: 5% auto !important;

    }
    .MuiTextField-root {
        width: 100%;
    }

    .updateblncBtn button {
        font-size: 13px !important;
        padding: 4px 7px !important;
    }
    .requesttype {
        padding: 15px !important;
    }

    input[type="file"] {
        padding: 5px 3px 10px!important;
    }
    .tltleft {
        font-size: 16px !important;
        flex-basis: 34% !important;
    }
    .icondefine {
        flex-wrap: wrap;
    }
    .top-recharge{
        padding-left: 8px;
        margin-top: 10px;
    }
    .tlttxtset {
        flex-basis: 40% !important;
    }
    .txtsetinput {
        width: 100% !important;
    }
    .boxamountwidth {
        width: 100% !important;
    }

    .uploadBtn{
        margin-left: 9px;
    }

    label.rnditemsradio span:first-child {
        left: -47px !important;
    }
    .layover_rd {
        font-size: 10px !important;
        padding: 6px 6px !important;
        left: 35% !important;
     }
    span.roundtoname {
        font-size: 11px !important;
    }
    .roundflightairportaddress {
        padding-left: 15px !important;
    }

    .roundarrowtabinsidebox img {
        top: 5px !important;
        width: 36px !important;
        right: 22px !important;
    }
    .roundflightairportaddress p {
        margin-bottom: 5px !important;
    }
    .fc .fc-view-harness{
        height: 100vh !important;
        overflow: auto !important;
    }

    .fc .fc-scrollgrid-liquid {
        width: 1120px !important;
        overflow: scroll;
    }
    .calendarBox{
        width: 100% !important;
    }
    .fc .fc-daygrid-body {
        width: 100% !important;
    }
    .alignleft{
        display: block !important;
        padding: 0px 15px !important;
    }
    .roundtltBaggage {
        font-size: 9px !important;
    }
    .btn-booking-roundtrip {
        min-width: 76px!important;
        font-size: 12px !important;
        padding: 2px !important;
    }
    .bgcolor {
        padding: 15px 0px !important;
    }

    .swipeboxModify-res{
        top: 32% !important;
        left: 45% !important ;
    }

    .roundtripresult-mob .filter-msg-output {
        font-size: 12px !important;
        padding: 20px 0px 0px !important;
    }
    .filter-msg-output {
        font-size: 12px !important;
        padding-bottom: 30px !important;
    }
    .r-table-container {
        overflow-x: auto !important;
    }
    .filter-btn-float .btn-primary, .filter-btn-float .btn-primary:hover {
        color: #fff;
        background-color: #DC354B !important;
        border-color: #DC354B !important;
        font-size: 14px;
        font-weight: 400;
        padding: 12px;
        outline: none !important;
        box-shadow: none !important;
    }

    .filter-btn-float  {
        position: fixed;
        background: #DC354B;
        width: 100%;
        bottom: 0;
        left: 0;
        text-align: center;
        z-index: 99;
        border: none;
        border-radius:0px ;
    }
    .filter-mob ,.sortby-mob {
        position: fixed !important;
        height: 100vh;
        width: 87% !important;
        overflow: auto;
        z-index: 99;
        display: none;
        background: #fff;
        padding: 5% 10px 50% 10px !important;
    }
    .d-block-xs{
        display: block !important;
    }
    .btn-primary {
        color: #fff;
        background-color: #fc9f14;
        border-color: #fc9f14;
    }
    .flighttltname {
        font-size: 10px !important;
    }
    .flightsubtltname {
        font-size: 8px !important;
    }
    .flightsearchmodify-btn button {
        font-size: 11px !important;
        padding: 4px 2px !important;
        font-weight: 600 !important;
        border: none !important;
    }
    .flightsearchmodify-btn svg{
        font-size: 12px !important;
    }
    .modify-col{
        border-right:0px !important ;
    }
    .flex-servicesbox {
        width: 107% !important;
    }
    .flighttabsdetails-link {
        padding: 0px 5px !important;
        font-size: 12px !important;
    }
    .tltBaggage-desc {
        font-size: 12px !important;
    }
    .layover {
        font-size: 10px !important;
        top: -11px !important;
        left: 32% !important;
    }
    span.icoarrowfromto img {
        width: 18px !important;
        opacity: 1 !important;
    }

    .flightsearchmodify-btn {
        text-align: center;
    }
    .flight-details-mob {
        display: none !important;
    }
    .flight_details_btn{
        width: 100% !important;
        margin-top: 10px;
    }
    .flight_details_oneway::after, .flight_details_round::after{
        background-color: transparent !important;
    }
 
   
    .modify-section {
        justify-content: flex-start !important;
    }
    .footerbookingflightnumber {
        font-size: 10px !important;
    }
    h3.tlt-lable {
        font-size: 18px !important;
    }
    .tlt-depart {
        font-size: 17px !important;
    }
    .date-depart {
        font-size: 10px !important;
        margin-top: 0px !important;
    }
    .abstlt {
        font-size: 17px !important;
    }
    .MuiDialog-paperScrollPaper {
        margin: 5px !important;
        width: 100% !important;
    }
    .btnmarkup{
        margin-top: 10px !important;
    }
    .updateTlt_hotel {
        font-size: 11px !important;
    }

    .viewinputhotel {
        font-size: 10px !important;
    }
    button.updateBtnHotel {
        font-size: 9px !important;
        padding: 4px 0px !important;
    } 
    .offernpricedtls {
        margin-top: 0px !important;
    }
    .ratedetailcard {
        width: 100% !important;
        padding: 10px !important;
        margin-left: 0px !important;
    }
    .roomrent {
        padding: 10px 0px !important;
        font-size: 15px;
        text-align: left !important;
    }
    .cancellationboxhotel {
        padding: 0px 0px 10px !important;
    }
    .roombox {
        padding: 15px 0px !important;
    }
    .btnbookhotel {
        padding: 15px 0px !important;
    }
    .fc-theme-standard td, .fc-theme-standard th{
        width: 130px !important;
    }
    .sideimgboxleft {
        padding-right: 0px !important;
    }
    .slideimgbox {
        width: 100% !important;
        margin-top: 55px !important;
    }
    .colspacemini {
        padding: 0px 15px !important;
    }
    .galleryslider .slick-slide {
        height: auto !important;
    }
    .galleyheader{
        width: 100% !important;
    }
    .tabcontentsbox .roundmini-tag {
        margin-left: 0px !important;
        margin-bottom: 10px;
    }
    .roundtripresult-des{
        display: none;
    }
    label.rnditemsradio span {
        margin-left: 30px !important;
    }

    .roundtripdetails-mini .roundflightimg {
        display: block !important;
        align-items: flex-end;
    }
    .roundflightdetailscard {
        /* padding-left: 12px !important; */
        padding: 10px 0px 0px !important;
    }
    .flightminitime {
        font-size: 13px !important;
    }
    .arrow-img-round img {
        top: 17px !important;
        width: 13px !important;
        left: 56% !important;
    }

    ul.roundlisticon {
        display: none !important;
    }
    .roundflightimg {
        display: block !important;
    }
    .roundarrow-img img {
        left: 4px !important;
        width: 20px !important;
    }
    .round-radio-fieldwith-iconlist {
        padding-left: 0px !important;
    }
    label.rnditemsradio span {
        margin-left: 21px !important;
        margin-top: -1px;
    }
    .tltrndheader {
        color: #DC354B;
        font-size: 14px;
    }

    .roundtripdetails-mini label.rnditemsradio span{
       display: none;
    }
    .roundtripdetails-mini ul.roundservicesBox {
        margin: 5px 10px;
    }
    .roundtltBaggage-desc {
        font-size: 10px !important;
    }

    .define-timecalc {
        font-size: 8px !important;
        padding-top: 4px !important;
    }
    .roundtripdetails-mini .flagflt {

    }
    .roundflightdetailscard-xs {
        padding: 10px 7px 5px;
        background: #fff;
        border-radius: 0px 10px 10px 0px;
        margin-bottom: 15px;
        box-shadow: -8px 3px 5px 1px rgb(0 0 0 / 15%);
    }
    .define-stop {
        font-size: 8px !important;
    }
    .round-highlight-price {
        font-size: 14px !important;
    }
    .flighttimexs{
        font-size: 11px !important;
    }
    .flexset-rnd-inside {
        padding: 0px 10px !important;
    }
    .flightmininame {
        font-size: 9px !important;
    }
    .flightminidata {
        font-size: 8px !important;
    }
    .rndflightname {
        font-size: 10px !important;
        width: -webkit-max-content;
        width: max-content;
    }
    .rndflightnumber {
        font-size: 11px !important;
        width: -webkit-max-content;
        width: max-content;
    }
    .share-cbx-round {
        margin-top: 6px !important;
    }
    .roundtripdetails-mini .roundmini-tag {
        margin: 5px 0px !important
    }
    .tltrndheader{
        white-space: normal !important;
        white-space: initial !important;
    }
    .roundflightdetailscard {
        margin-bottom: 10px !important;
    }
    .scroll-roundtripresult{
        height: 100vh !important;
        overflow: scroll !important;
    }
    .share-container {
        bottom: 44px !important;
        width: 250px !important;
        padding: 10px 15px !important;
    }
    .share-ttl-1 {
        margin-bottom: 0px important;
        font-size: 11px !important;
    }
    .share-outline-input {
        margin-top: 0px !important;
    }
    .share-row-wrapper {
        margin-top: -8px !important;
    }
    .arrowimg-mob{
        top: 5px !important;
    }
    
    .flightairportaddress {
        padding: 0px 15px !important;
    }
    .viewresultbooking {
        padding: 5px 0px !important;
    }
    .flightnumber {
        width: -webkit-max-content;
        width: max-content;
        font-size: 10px !important;
    }
    .departflightminitime {
        font-size: 15px !important;
    }
    .departflightname {
        font-size: 13px !important;
    }
    .departflightnumber {
        font-size: 9px !important;
    }
    .departimgminifly {
        margin-right: 12px !important;
    }
    .departdefine-stop , .departdefine-timecalc {
        font-size: 10px;
    }
    .baggagedepart {
        padding: 15px 0px !important;
    }
    .departcode {
        font-size: 18px !important;
    }
    .baggageBox {
        margin-left: -10px !important;
    }
    .items-head {
        padding: 0px 15px !important;
    }
    span.boldtxt {
        font-size: 14px !important;
    }
    .definedepartpwidth {
        margin-top: 10px;
    }
    .table-mob{
        min-width: 100% !important;
    }
    .share-text-input input{
        margin-top: 0px !important;
    }
    .choosebtn {
        margin-left: 0px !important;
    }
    .para-txt{
        padding: 10px !important;
    }
    .insideRow {
        padding: 0 0% !important;
    }
    .itemRow {
        padding: 20px 10px !important;
    }
    button.btnsubmitchange {
        min-width: 100px !important;
    }
    .tltprofilename {
        margin-left: 0% !important;
    }
    .balance_show {
        z-index: 9;
    }
    .tbleBox {
        display: block !important;
    }
    .tblecelltlt {
        width: 100% !important;
        text-align: left !important;
    }

    .titlepricetag {
        flex-basis: 30% !important;
    }
    label.itemsradio span {
        font-size: 11px !important;
    }
    li.listitemsicon img {
        margin: 5px 2px  !important;
    }
    .share-cbx {
        margin-top: 10px;
        width: -webkit-max-content !important;
        width: max-content !important;
    }
    .setbtnprevnext {
        width: 100% !important;
        justify-content: start !important;
    }
    .roundmini-tag {
        margin-left: 1px !important;
    }
    .moreOption{
        padding: 10px 0px;
    }
    .flightsearchmodify-btn {
        text-align: right !important;
        padding: 5px !important;
    }
    .onewaycontainer .flightmininame {
        font-size: 9px !important;
    }
    .onewaycontainer .share-cbx {
        margin-top: 10px !important;
        margin-left: 35% !important;
        top: -10% !important;
    }
    .filter-btn-float svg.MuiSvgIcon-root {
        margin-top: -2px;
    }
    .sortby-des{
        display: none !important;
    }

    .sortby-label {
        font-size: 14px  !important;
        color: #000  !important;
    }
    .sortby-mob .MuiFormControlLabel-label{
        font-size: 12px;
    }
    .sortby-mob .MuiRadio-colorPrimary {
        padding: 0px 10px !important;
    }
    .sortby-mob .viewresultbooking.addonbackground.row {
        background: #fff !important;
    }
    .sortby-radiogrp{
        align-items: end;
    }
    .sortby-radiogrp label{
        margin: 2px 0px !important;
    }
    .sort-by-border{ 
        border-bottom: 1px solid #ccc;
        padding: 20px 0px;
    }
    .roundtltfaredetails {
        font-size: 11px !important;
    }
    .roundtlt-total-right{
        text-align: right;
    }
    /* .MuiNativeSelect-select {
        padding: 0px 0 5px !important;
    } */
    .roundtabsBox {
        margin: 2px 10px !important;
    }
    .onewaycontainer .arrowtabinfo img {
        left: 13px !important;
        width: 30px;
    }
    .onewaycontainer .flightairportaddress {
        padding: 0px 0px !important;
    }
    .onewaycontainer .arrowtabtxt {
        font-size: 9px !important;
    }
    .onewaycontainer .tltdate {
        margin-top: 0px !important;
    }
    .onewaycontainer .define-timeinfo{
        padding-top: 12px !important;
    }
    .tlt-total-left{
        text-align: left !important;
    }
   
    .definewidth {
        margin: 0px !important;
    }
    .Subtlt-traveller {
        font-size: 10px !important;
        padding: 2px 0px !important;
    }
    .px-xs-0{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .pl-xs-0{
        padding-left: 0px !important;
    }
    .pr-xs-0{
        padding-right: 0px !important;
    }
    .pt-xs-3{
        padding-top: 15px !important;
    }
    .pb-xs-3{
        padding-top: 15px !important;
    }
    .sideimgboxright {
        display: flex !important;
    }
    .arrowtabtxt {
        font-size: 10px !important; 
    }
    .arrowbox-multi{
        width: 50px !important;
    }
    .multi-flighdatetime{
        text-align: center;
    }
    button.seatbtnpopup {
        font-size: 10px !important;
        min-width: 110px !important;
    }
    .inputBoxgst{
        margin-top: 10px;
    }
    .dailogBox-tlt {
        display: block !important;
    }
    .dropdown-profile {
        width: 100% !important;
        margin-left: 0px !important;
    }

    .login-right-mob{
        display: none !important;
    }
    .logo-account {
        margin-left: 0px !important;
    }
    .header-logo {
        width: 65% !important;
        padding-top: 15px !important;
    }
    .navbar {
        padding: 1.5rem 1rem !important;
    }
    .viewroundresultbooking{
        padding: 3px 0px !important;
    }
    .viewroundresultbooking .MuiNativeSelect-select {
        font-size: 13px !important;
        padding: 0px!important;
    }
    .faretype-p {
        font-size: 13px;
    }
    .swipebox_modify{
        z-index: 9 !important;
    }
    .tabsBox .flightimg {
        display: block !important;
    }
    .roundviewbtndetails {
        margin-top: 4px !important;
    }
    .btnview {
        font-size: 10px!important;
        width: -webkit-max-content !important;
        width: max-content !important;
        padding: 5px 7px!important;
    }
    .arrowmultiImg img{
        left: 5px !important;
    }
    .multiarrowimg{
        top: 5px !important;
    }
    button.btnEditProfile {
        font-size: 12px !important;
    }

    .hoteldsgn {
        max-width: 100% !important;
    }
    button.btntrnlog{
        min-width: 100px !important;
    }
    .share-container, .share-container-round {
        bottom: 44px !important;
        width: 250px !important;
        padding: 10px 15px !important;
    }
    .imgdepartbooking {
        display: block !important;
    }
    .subtlt-fly {
        font-size: 11px !important;
    }
    .tlt-fly {
        font-size: 16px !important;
    }
    .MuiMenu-list li {
        width: 100% !important;
    }
    .offline-total-ttl{
        padding: 0px 8px;
        font-size: 17px;
    }
    .Fdetails {
        font-size: 12px !important;
    }
    .Udetails {
        font-size: 13px !important;
    }
    .selecthoteltabs .MuiTab-root {
        min-width: 120px !important;
    }
    .MuiTabs-scroller {
        overflow: auto !important;
    }
    .share-txtbtn {
        font-size: 11px !important;
    }
    .searchtab-main {
        width: 100% !important;
        position: relative !important;
    }
    .search-box .MuiTabs-flexContainer {
       display: -webkit-box !important;
    }
    .div-border-right {
        display: none;
    }
    .search-wpr .box-input-calender {
        width: 100% !important;
    }
    .search-wpr .box-input-passclass {
        width: 100% !important;
    }
    .MuiButton-root {
        float: revert;
    }
    .search-wpr {
        display: flex;
        padding: 10px !important;
        border-radius: 20px;
        background: #F2F5F9;
    }
    .search-wpr .mob-input-border{
        /* border: 1px solid #ccc; */
        border-radius: 10px ;
        margin-bottom: 10px;
        display: block !important;
    }
    .search-wpr .mob-input-border-r{
        /* border: 1px solid #ccc; */
        border-radius: 10px ;
        margin-bottom: 10px;
        display: block !important;
    }
    .modifydata .MuiTab-root {
        font-size: 13px !important;
        padding: 0px !important;
    }
    .search-box .MuiTabs-scroller {
        /* overflow: hidden !important; */
    }
    .search-wpr .box-input-w {
        padding-top: 10px !important;
    }
    .search-wpr .inputFieldLocation{
        margin-bottom: 2px!important;
    }

    .modifydata .MuiTabs-flexContainer{
        width: 100% !important;
    }
    .m-inherit {
        margin: unset !important;
    }
    .linklistul ul {
        font-weight: 500;
        padding: 0px 10px !important;
    }
    .banner-bg-image {
        background-size: cover !important;
    }
    .footer {
        padding: 20px 0px 20px !important;
    }
    .banner-des-1, .banner-des-2, .banner-des-3, .banner-des-4, .banner-des-5{
        width: 100% !important;
        font-size: 13px !important;
    }
    .banner-1, .banner-2, .banner-3, .banner-4, .banner-5{
        padding-top: 50px !important;
    }
    .navbar {
        padding: 0.8rem 1rem !important;
    }
    .imgfnd img {
        width: 100%;
    }
    .tltrndheader-flight {
        background: #f2f5f9;
        padding: 2px 10px;
        font-size: 12px;
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .itemssecttag {
        font-size: 8px !important;
    }
    .addon-btn {
        width: 100%;
        margin-left: 0%!important;
    }
    .filter-msg-output-multi{
        padding: 5px 0px !important;
    }
    .setbtnprevnext-multi{
        padding: 0px 10px !important;
    }
    .shownetmulticities {
        margin-top: 3px !important;
        font-size: 14px !important;
    }
    .modify-section-des{
        display: none;
    }
    .modifyheadercontent{
        width: 100%;
        padding: 12px 0px !important;
        position: fixed;
        z-index: 99;
        top: 0px;
    }
    .departcodedetails span {
        font-size: 11px !important; 
    } 
    .sectorToggleBtn {
        font-size: 12px !important;
        padding: 5px 2px !important;
    }
    .sectorToggleBtnActive{
        font-size: 12px !important;
        padding: 5px 2px !important;
    }
    .tlt-traveller{
        display: flex;
    }
    .texticon svg {
        font-size: 43px !important;
    }
    .txt_btn{
        text-align: center !important;
        margin-top: 30px !important;
        justify-content: center;
        display: flex;
    }
    .txt_btn button{
        font-size: 10px !important;
    }

    .tdledger p{
        width: -webkit-max-content;
        width: max-content;
    }

    .voucher-container {
        zoom: 0.35;
    }

    .button.selectbtnOutline{
        margin-top: 10px;
    }
    .sectorToggleBtnActive{
        padding: 5px 15px !important;
    }
    .sectorToggleBtn{
        padding: 5px 15px !important;
    }

    .report-more-btngrp {
        text-align: left !important;
    }

    .baggage-heading li {
        font-size: 12px !important;
    }
    .meal-img img {
        width: 100% !important;
    }
    .meal-price {
        font-size: 13px !important;
    }
    .baggage-img img {
        width: 100% !important;
        padding: 3px !important;
    }
    .tlthtlstr {
        font-size: 18px !important;
    }
    .hoteladdress p {
        font-size: 12px !important;
    }
    .ratedetailcard {
        padding: 20px !important;
    }
    .galleryslider{
        margin-top: 18% !important;
    }
    .tabsPanelContainer{
        padding: 0px !important
    }
    .account-ledger{
        margin: 15px 0px !important;
    }
    .tlt-content-ledger , .table-box-found {
        padding: 0px 15px !important;
    }
    .tlt-ledger {
        padding: 15px 0px !important;
    }
    .searchtab-sp{
        width: 40% !important;
    }
    .offer-bg-img {
        height: 300px !important;
    }
    .special-offer-right .flightmininame {
        font-size: 13px !important;
        text-align: center;
    }
    .special-offer-right .arrowbox {
        width: 14% !important;
    }
    .specailfarelabel {
        padding: 10px 0px;
        font-size: 20px !important;
    }
    .boxdivition-row {
        padding: 15px 0px !important;
    }
    .moreBtnList {
        left: 18% !important;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
    .balanceBox {
        margin: 0px !important;
    }
    .flight-invoice-container{
        zoom: 0.4;
    }
    .tltfaretype-desc {
        position: relative !important;
        padding: 0px 15px 0px !important;
        right: 0px !important;
        top: 10px !important;
    }
    .search-box-hotel{
        margin-top: -70% !important;
    }
    .special-offer-button{
        height: 300px !important;
    }
    .top-up-footer-spinner .divLoader {
        margin-bottom: 17% !important;
    }
    .top-up-footer-spinner .svgLoader {
        width: 100px !important;
    }
    .banner-text{
       display: none;
    }

    .banner-des-1, .banner-des-2, .banner-des-3, .banner-des-4, .banner-des-5,  .banner-des-6, .banner-des-7, .banner-des-8, .banner-des-9{
        width: 100% !important;
        font-size: 15px !important;
    }
    .btnSend {
        padding: 6px 25px !important;
    }
    .rightBoxContainer {
        padding: 15px 0px 0px !important;
    }
    
    .itemsclockicon svg.MuiSvgIcon-root {
        font-size: 25px !important;
        margin-right: 5px ;
    }
    .header-topup {
        margin-bottom: 15px;
    }
}
@media (min-width: 551px){
    .banner-component-desk{
        display: block !important;
    }
    .banner-component-mob{
        display: none !important;
    }
}

@media (max-width: 550px){
    .experience-desktop{
        display: none;
    }
    .experience-mobile{
        display: flex !important;
    }
    .card-img img{
        width: 100%;
        height: 180px;
        border-radius: 16px;
    }
    .footeraddress1 p{
        word-break: break-word;
    }
    .flightsearch-box {
        display: flex !important;
        padding: 0px 5px !important;
    }
    
    .icoBox {
        width: 86px !important;
        font-size: 14px !important;
    }
    img.icostatic {
        width: 41px !important;
        height: 41px !important;
    }

    .tlt-services {
        width: 100% !important;
        margin-left:  25px !important;
        padding-right: 15px;
    }
    .tlt-services ul {
        display: flex;
    flex-wrap: wrap;
    }

    .box-prst {
        height: auto !important;
    }

    h2.tlt-link {
        margin-right: 0px !important;
        font-size: 19px !important;
        margin-top: -12px ;
    }
    .loginBox {
        margin: 20px 0px !important;
    }
    .box-prst {
        padding: 10px 30px !important;
    }
    .tlt-services ul li {
        margin: 0 5px;
        width: 100%;
        padding-right: 20px;
    }
    .footeraddress {
        padding: 5px 10px;
    }

    .socialicons {
        margin-bottom: 10px;
    }

    .registerBox {
        margin: 15px 0px !important;
    }

    span.linkcreateaccount {
        display: block;
    }

    .gst_width {
        width: 100% !important;
        padding: 0px 35px !important;
    }

    .gst_width .tlt-form {
        flex-basis: 60% !important;
    }
    .managegstrow_new.row {
        padding: 0px !important;
    }

    .updateblncBtn button {
        margin-right: 10px !important;
    }

    .balncContainer .inputrow{
        padding: 0px 15px !important;
    }

    .Slider-deales-xs{
        margin-top: 5% !important;
    }
    .resulthoteltlt{
        margin: 0px !important;
        padding: 15px 0px !important;
        margin-bottom: 15px !important;
    }
    .tlt-hotel {
        margin-top: 10px;
        margin-bottom: 5px !important;
    }
    .addresshotel p {
        margin-bottom: 5px !important;
    }
    .facilites span {
        font-size: 11px !important;
    }
    
    .hotelrow img{
        object-fit: cover;
    }
    .hoteltabs .MuiTabs-scroller {
        overflow: auto !important;
    }
    .tltbooknow button {
        font-size: 10px !important;
    }
    .flexdiv{
        margin-top: 7% !important;
    }
    .sideimgboxright button{
        font-size: 12px !important;
        min-width: 50px !important;
    }
    .overlayposotion {
        top: 4px !important;
        left: 31% !important;
        font-size: 10px !important;
    }
    .define-timecalc, .define-stop{
        text-align: center;
    }
    .heading-right {
        flex-basis: 60%;
    }

    .px-xs-3{
        padding: 0 15px;
    }
    .sort-content-box{
        display: block !important;
    }
    .container-not-found{
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 20px 0px;
    }
    .flightimg{
        display: block !important;
    }
    .banner-component-desk{
        display: none !important;
    }
    .banner-component-mob{
        display: block !important;
    }
}

@media (max-width: 375px){

    .btn-collapse button {
        padding: 6px 7px!important;
        font-size: 12px!important;
    }
}

@media (max-width: 330px){
    .flightsearch-box {
        display: block !important;
        padding: 0px 5px !important;
    }

    .footer .container-fluid{
        padding: 0px !important;
    }
    .updateTlt_hotel {
        font-size: 10px !important;
    }

    .setbtnprevnext {
        width: 100% !important;
        padding: 0px 20px 10px !important;
    }

    .icoBox {
        width: 70px !important;
        font-size: 14px !important;
    }
    button.tabTilte {
        min-width: 68px !important;
    }

    .Slider-deales-xs {
        margin-top: 76% !important;
    }
    .flightminitime {
        font-size: 8px !important;
    }
    .round-highlight-price {
        font-size: 8px !important;
        margin-bottom: 3px;
    }
    .share-cbx-round {
        margin-left: -5px !important;
    }
    .flightimg {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .supplier-txt {
        width: auto !important;
    }
    .prstColr {
        padding-left: 10px!important;
    }
    svg.locationiconr {
        left: 14px !important;
    }
    .addon-btn {
        width: 100% !important;
        margin-left: 0px !important;
        margin-top: 10px !important;
    }
    .swipebox {
        top: 74px !important;
    }
    .share-cbx {
        width: -webkit-max-content !important;
        width: max-content !important;
    }
  
}

@media (min-width: 787px) {
    .modify-section-xs{
        display: none;
    }
    
    .filter-btn-float{
        display: none;
    }

    .filter-mob{
        display: block !important;
    }

    .btnview {
        width: 100% !important;
        font-size: 10px !important;
    }
    .roundtripresult-mob{
        display: none;
    }
    .sortby-mob{
        display: none;
    }
    .banner-component-mobile{
        display: none;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

@media (min-width: 1200px) {
    .font-large{
        font-size: 24px;
    }
    .font-large-bold{
        font-size: 24px;
        font-weight: 700;
    }
    .font-medium{
        font-size: 18px;
    }
    .font-medium-bold{
        font-size: 18px;
        font-weight: 700;
        text-transform: capitalize;
    }
    .font-small{
        font-size: 14px;
    }
    .font-small-bold{
        font-size: 14px;
        font-weight: 700;
    }
}
@media (min-width: 900px){
    .show-filter-btn{
        display: none;
    }
    .sort-content-box{
        display: none !important;
    }
    .sort-by-desktop, .filter-desktop{
        display: block;
    }
    .show-tooltip-mob{
        display: none;
    }
    .show-tooltip-desk{
        display: block;
    }
}


.singleimgbox-1 {
    width: 100%;
    margin-bottom: 20px;
    padding-right: 10px;
}
.singleimgbox-1 img {
    width: 100%;
    object-fit: cover;
    height: 250px;

}

.breadcrumb-custom.margin {
    margin-top: 40px;
}
/* .colspace {
    padding: 10px!important;
} */

.freeCancRow{
    padding: 0px 15px;
}
.colspacemini {
    padding: 0px;
}
.multiimgbox  {
    width: 100%;
}
.multiimgbox img {
    width: 100%;
    height: 160px;
    border-radius: 10px;
    margin-bottom: 15px;
    object-fit: cover;
}
.sideimgboxleft {
    width: 100%;
    height: 100%;
}
.sideimgboxright {
    width: 100%;
    height: 100%;
    position: relative;
}

.sideimgboxleft img {
    height: 80px;
}
.sideimgboxright img {
    height: 80px;
}

a.linkviewhotel {
    position: absolute;
    text-align: center;
    top: 50px;
    left: 40px;
    z-index: 9;
    font-size: 16px;
    font-weight: 500;
}
.sideimgboxleft img {
    width: 100%;
    border-radius: 10px;
}
.sideimgboxright img {
    width: 100%;
    border-radius: 10px;
}
.ratedetailcard {
    width: 95%;
    /* height: 45%; */
    padding: 10px;
    margin-left: 13px;
    border-radius: 23px;
    border: 1px solid #808080;
    /* border-bottom: 0px; */
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
}
.tltcarddts {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px;
}

.boxprst {
    display: flex;
    padding: 0px;
    width: 100%;
}
.chargedts {
    flex-basis: 70%;
    text-align: left;
}
.chargeprice {
    flex-basis: 30%;
    text-align: right;
}
.item-rateoff {
    font-size: 14px;
    font-weight: 500;
    text-decoration: line-through;
    color: #999;
}
.item-rateopen {
    font-size: 17px;
    font-weight: 500;
}
.item-frt {
    font-size: 14px;
    font-weight: 500;
    color: #5d5a5a;
}
.appliedbox {
    font-size: 14px;
    font-weight: 700;
    text-align: right;
    color: green;
}
.nonrefundico {
    font-size: 14px;
    font-weight: 500;
}
.nonrefundico svg {
    color: red;
}
.nonrefundico span {
    margin-left: 8px;
}

.nonrefundicochecked {
    font-size: 14px;
    font-weight: 500;
}
.nonrefundicochecked svg {
    color:blue;
}
.nonrefundicochecked span {
    margin-left: 8px;
}
.flexdiv {
    display: flex;
    align-items: center;
    width: 107%;
    padding: 10px;
    border: 1px solid #808080;
    margin: -11px;
    margin-top: 22%;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
}
.tltbooknow button {
    font-size: 12px;
    border-radius: 30px;
}
.tltdiv {
    font-size: 14px;
    font-weight: 500;
    flex-basis: 50%;
}
.tltbooknow {
    flex-basis: 50%;
    text-align: end;
}
.tlthotelwithstr {
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 16px !important;
    overflow: hidden;
}
.tltstr {
    margin-left: 20px;
}
.tltstr ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.tltstr ul li {
    margin-right: 8px;
}
.tlthtlstr {
    font-size: 20px;
    font-weight: 500;
    color: #0b78bc;
}
.hoteladdress p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
}
.tabsrow {
    margin: 0;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 15px;
    background: #fff;
    border-radius: 12px !important;
}
.tabboxhotel {
    padding: 10px;
}
.desc-tlt {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 6px;
}
ul.tabullist {
    list-style-position: outside;
    list-style-position: initial;
    padding-left: 20px;
}
ul.tabullist li {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.8;
}
.paratabtext p {
    font-size: 15px;
    font-weight: 500;
}
.coltabspace {
    padding: 0!important;
}
.roombox {
    padding: 15px;
}
.roomdtlstlt {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}
.miniico-roomdtls {
    font-size: 13px;
    font-weight: 500;
    color: #565656;
    position: relative;
    padding-left: 25px;
}
.miniico-roomdtls svg {
    position: absolute;
    top: 1px;
    left: 0;
}
.cancellationboxhotel {
    padding: 15px 0;
}
.ltlmode {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0px;
}
.ltlmode svg {
    color: #3BB54A;
    font-size: 22px;
}

.free_cancel {
    font-size: 14px;
    font-weight: 500;
    /* margin-bottom: 10px; */
}
.free_cancel svg {
    color: #3BB54A;
    font-size: 20px;
}
.free_cancel span {
    margin-left: 8px;
}

.ltlmode span {
    padding-left: 4px;
}
span.datesetsw {
    font-size: 14px;
}
button.vewclsbtn {
    font-size: 13px;
    text-transform: lowercase;
    /* margin: 11px 3px; */
}
.msgprovider {
    display: inline-block;
    padding: 5px;
    margin-top: 10px;
    background: #3BB54A;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    cursor: context-menu;
}
.roomrent {
    padding: 15px;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
}
.btnbookhotel {
    padding: 15px;
}
.btnbookhotel button {
    width: 100%;
    font-size: 15px;
    padding: 5px;
}
.roomdetailsbookingbox {
    border-radius: 8px;
    box-shadow: 0px 1px 7px -1px rgb(0 0 0 / 30%);
    margin-left: 5px!important;
    margin-right: 5px!important;
    margin-bottom: 8px!important;
}
.mainaddonbox {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.bosaddonfilter {
    padding: 10px 15px;
    margin-right: 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 7px -1px rgb(0 0 0 / 30%);
}

label.labelcontroladdoncheck {
    width: 100%;
    margin: 0;
    /* margin-bottom: 10px; */
}
label.labelcontroladdoncheck span {
    padding: 0;
    padding-right: 8px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.pointercls{
    cursor: pointer;
}

li.cancel-policy{
    margin-bottom: 0px;
    font-size: 14px;
}
.refreshmentIcon {
    font-size: 14px !important;
    margin-bottom: 2px;
    margin-right: 4px;
}
.inclution-list{
    list-style: none;
    font-size: 12px;
    padding: 0px;
    padding-right: 15px;
    border-right: 1px dotted #ddd;
}
.inclution-list svg{
    color: #fff;
    font-size: 13px !important;
    background: #3aa014;
    margin-top: 4px;
    margin-right: 11px;
}


.inclution-list-simple{
    font-size: 12px;
    padding: 0px;
    margin: 0;
}

.exclution-list{
    list-style: none;
    font-size: 12px;
    padding: 0px;
}

.exclution-list svg{
    color: #fff;
    font-size: 13px !important;
    background: #EB2026;
    margin-top: 4px;
    margin-right: 11px;
}

.inclution-list li, .exclution-list li, .inclution-list-simple li{
    margin-bottom: 6px;
    font-size: 14px;
    display: flex;
}
label.filtersroompage span {
    padding: 0;
    padding-right: 8px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.bosaddonfilter {
    padding: 10px 15px;
    margin-right: 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 7px -1px rgb(0 0 0 / 30%);
}

label.filtersroompage {
    width: 100%;
    margin: 0;
    margin-bottom: 0px;
}



.act-txt-xs{
    font-size: 12px;
    font-weight: 500;
}

.act-search-input{
    width: 100%;
}

.act-heading-1 {
    font-size: 22px;
    font-weight: 700;
    padding: 20px 0px 10px;
}

.activity-container .flitercolumstick {
    position: static !important;
    position: initial !important;
    top: 0;
}

.actfare-wrapper{
    padding: 10px 30px;
}

.actfare-ttl-1{
    color: #FF0000;
    font-size: 10px;
    margin-bottom: 0;
}

.actfare-ttl-2{
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
}

.actfare-ttl-3{
    font-size: 10px;
    font-weight: 600;
}

.actfare-ttl-11{
    color: #1676b8;
    font-weight: 700;
    margin-bottom: 0;
}

.actfare-ttl-light{
    color: #9e9e9e;
    font-size: 10px;
    margin-bottom: 0;
}

.actfare-ttl-bold{
    margin-top: 10px;
    font-size: 13px;
    color: #000;
    margin: 0;
}

.act-select-wrapper .linkviewhotel{
    font-size: 14px;
}

.package-wrapper{
    padding: 15px;
}
.package-wrapper .btn-border, .package-wrapper .btn-border:hover,.package-wrapper .btn-border:active{
    border-radius: 4px !important;
    background: #fff !important;
    color: #DC354B !important;
    border: 1px solid #DC354B !important;
    font-size: 15px;
}

.package-wrapper .btn-border-radius{
    border-radius: 4px !important;
    background: #DC354B !important;
    color: #fff !important;
    border: 1px solid #DC354B !important;
    font-size: 15px;
}
.package-ttl-1 {
    display: flow-root;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
}
.package-heading{
    display: flow-root;
    padding-bottom: 15px;
    border-bottom: 1px solid;
    font-weight: 700;
}
.package-price{
    display: flow-root;
    padding-bottom: 15px;
    font-weight: 600;
    font-size: 16px;
}
.pull-right{
    float: right;
}
.pull-left{
    float: left;
}

.package-ul{
    padding: 0px 5px;
    /* border-right: 1px solid #ccc; */
}

.txt-xs{
    font-size: 11px;
    font-weight: 600;
}

.person-btn-grp{
    margin: -6px;
}

.person-btn-grp button{
    color: #000 !important;
}

/* activity booking css */

.actbooking-img-1 img{
    width: 100%;
    height: 88px;
    border-radius: 10px;
}
.xs-txt-p{
    font-size: 13px;
    font-weight: 600;
    color: #DC354B;
    margin-top: 10px;
    margin-bottom: 0px;
}

.info-box {
    border-right: 1px solid #808080;
}

.booking-package-ul {
    padding: 0px 15px;
}

.abstlt {
    display: inline-block;
    padding: 10px 15px;
    background: #0b78bc;
    color: #fff;
    margin-left: -16px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.tlt-input-traveller{
    /* margin-bottom: 5px !important; */
}

.calcBox {
    margin-top: 15px;
    border-radius: 9px;
    padding-top: 10px;
}

.tlt {
    font-size: 16px;
    font-weight: 700;
}

.backtosearch {
    display: flex;
    align-items: center;
}

.fare-tlt {
    font-size: 18px;
    font-weight: 500;
    flex-basis: 67%;
}

button.setbtnback {
    font-size: 13px;
    font-weight: 500;
    padding: 8px !important;
    text-transform: capitalize!important;
}

.selectactivity-container{
    padding-top: 15px !important;
}

.reviewbtncontent {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    /* box-shadow: 0 1px 5px -1px rgb(0 0 0 / 40%); */
    text-align: right;
}

.singleimgbox img {
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
    height: 290px;
}

.singleimgbox-banner img{
    width: 100%;
    object-fit: cover;
    border-radius: 16px;
    height: auto !important;
}

table.hotelcancellationtbl {
    width: 98%;
    text-align: center;
    margin: 10px auto 0;
}

table tr.firstrowtbl td {
    /* background: #6e9abf; */
    color: #000 !important;
    border: 0;
}

table.hotelcancellationtbl tr td {
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    font-weight: 500;
    color: grey;
}

.tlttravellertype {
    font-size: 12px;
    font-weight: 400;
    flex-basis: 100%;
}

.itinenary-list .MuiAccordionSummary-root {
        min-height: 30px !important;
}
.itinenary-list .MuiPaper-root{
    box-shadow: none;
}
.itinenary-list p{
    font-size: 12px;
    font-weight: 400;
}
.MuiAccordionSummary-content {
    margin: 5px 0 !important;
}

.itinenary-list p li{
    font-size: 14px;
    font-weight: 500;
    color: #000;
    text-transform: capitalize;
}

.hoteltab {
    border-bottom: 1px solid #ddd;
}

.view-images-btn{
    float: right !important;
    padding: 5px 0 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #666666 !important;
    background: #fff !important;
}

.view-images-btn:hover{
    background: #fff;
}

.slideimgbox .slick-prev:before, .slick-next:before {
    font-size: 32px !important;
    opacity: 1 !important;
    color: white !important;
}
.slideimgbox .slick-prev {
    left: -40px !important;
}

.activity-modal button{
    position: absolute;
}

.preview-modal .MuiPaper-root{
    max-width: 100%;
}

.close-modal-btn{
    float: right;
    z-index: 999999;
    color: #DC354B !important;
    font-weight: 800;
    border-radius: 8px !important;
    min-width: -webkit-max-content !important;
    min-width: max-content !important;
}

.close-modal-btn svg.MuiSvgIcon-root{
    font-size: 26px !important;
}

.package-list-ul-1 ul{
    list-style: none;
    padding-left: 15px;
}

.package-list-ul-1 ul li{
    font-size: 12px;
    margin-bottom: 5px;
    display: flex;
}

.package-list-ul-1 ul li::before{
    content: "\2714";
    font-family: FontAwesome;
    display: inline-block;
    background: #4caf50;
    color: #fff;
    font-weight: 300;
    font-size: 8px;
    padding: 0px 3px;
    width: 12px ;
    height: 12px;
    margin-right: 10px;
    margin-top: 3px;
}

.package-list-ul-2 ul{
    list-style: none;
    padding-left: 15px;
}

.package-list-ul-2 ul li{
    font-size: 13px;
    margin-bottom: 5px;
    display: flex;
}
.package-list-ul-2 li p{
    font-weight: 400;
    font-size: 13px;
}

.package-list-ul-2 ul li::before{
    content: "\2716";
    font-family: FontAwesome;
    display: inline-block;
    background: red;
    color: #fff;
    font-weight: 300;
    font-size: 8px;
    padding: 0px 3px;
    margin-right: 10px;
    width: 12px ;
    height: 12px;
    margin-top: 5px;
}



.cancel-hd button{
    position: absolute;
}

.cancel-db h5{
    padding: 0px 15px;
    font-size: 18px;
}

.cancel-policy-db ul li{
    font-size: 13px;
}

.package-highlights-list-ul-1 ul li::before {
    content: "\f058";
    font-family: FontAwesome;
    display: inline-block;
    color: #DC354B;
    font-weight: 300;
    font-size: 12px;
    padding: 0px 3px;
    width: 12px;
    height: 12px;
    margin-right: 10px;
}
.package-highlights-list-ul-1 p{
    margin-bottom: 0px;
    font-size: 12px;
}
.package-highlights-list-ul-1 ul li{
    padding-bottom: 3px;
    display: flex;
}

.package-highlights-list-ul-1 ul {
    list-style: none;
    padding: 0;
    font-size: 12px;
}
.image-gallery-slide .image-gallery-image{
    max-height: 500px !important;
}

.image-gallery-thumbnails-container{
    overflow: auto;
}
.image-gallery-slide-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 70px;
}

.package-highlights span {
    width: 100%;
    height: 90px;
    overflow: hidden;
    display: block;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    margin-bottom: 0;
}

.details-skltn-mob{
    display: none;
}

.club-vistara-wpr{
    background: #DC354B;
    padding: 12px 15px;
    color: #fff;
    margin-top: 10px;
    text-align: center;
}
.cv-inner-wpr{
    display: flex;
    justify-content: center;

}
.club-vistara-wpr img{
    background: #fff;
    width: 120px;
    border: 1px solid #DC354B;
}
.cv-logo{
    /* margin-top: -33px; */
    margin-bottom: 10px;
    /* margin: auto; */
}
.club-vistara-wpr p{
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 6px;
}
.cv-inner-left h4{
    font-size: 26px;
}
.cv-inner-left h5{
    color: #dbc59f;
    font-size: 21px;
    padding-top: 3.2px;
}
.cv-inner-right{
    padding: 1px 8px;
    display: flex;
}
.cv-inner-right h6{
    font-size: 24px;
    text-transform: uppercase;
    text-align: left;
    color: #dbc59f;

}

.cv-inner-right span{
    font-size: 24px;
    margin-left: 1px;
    color: #dbc59f;
    text-transform: uppercase;

}
.contact-ad{
    background: #dc354b0d;
    padding: 7px 15px;
    text-align: center;
    border-radius: 4px;
    
}
.contact-ad img{
    width: 130px;
    margin-bottom: 10px;
}

.contact-ad a{
    color: #fff !important;
    background: #DC354B;
    padding: 5px 10px !important;
    margin: 11px auto !important;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    border-radius: 8px;
}

.contact-login p{
    color: #DC354B;
    font-weight: 500;
}

.contact-login h6{
    color: #000;
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
}

.contact-wpr p span{
    font-size: 15px;
    font-weight: 500;
    color: #DC354B;;
}
.contact-box{
    display: flex;
}

.contact-box img{
    width: 150px;
}

.contact-box h1{
    font-size: 20px;
    color: #DC354B;
}
@media (max-width: 786px) {
    .actbooking-img-1 img{
        height: auto;
        width: 100px;
    }
    .singleimgbox img {
        height: 150px !important;
    }
    .singleimgbox-banner img{
        width: 100%;
        object-fit: cover;
        border-radius: 16px;
        height: auto !important;
    }
    .multiimgbox img {
        height: 80px !important;
    }

    .sideimgboxleft img , .sideimgboxright img{
        height: 45px !important;
    }
    .faq-section h5{
        font-size: 17px;
    }
    .package-list-ul-1 ul , .package-list-ul-2 ul{
        list-style: none;
        padding: 0px 15px;
    }
    .actfare-ttl-bold {
        font-size: 12px;
    }
    .fare-tlt {
        font-size: 17px;
    }
    .mt-xs-3{
        margin-top: 15px !important;
    }
    .reviewbtncontent {
        display: block !important;
        text-align: left;
    }
    .details-skltn-des{
        display: none;
    }
    .details-skltn-mob{
        display: block;
    }
}

.faq-search-box{
    /* text-align: right; */
    margin-bottom: 15px;
}
.faq-search-box .inputboxdetails{
    width: 100% !important;
}

.Noresult {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    padding: 25px;
    margin: 25px 0;
    background: aliceblue;
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 150px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.resulttlthotel {
    font-size: 18px;
    font-weight: 500;
    margin: 15px 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9;
    background: #fff;
    padding: 3px 15px;
    margin-left: -15px;
    width: 100%;
    border-radius: 8px;
}
.tlt-filter-header {
    display: flex;
    align-items: center;
}
.tltrangereset {
    flex-basis: 47%;
    font-size: 14px;
    font-weight: 500;
     color: tomato;
     cursor: pointer;
}
.tltrange-minimize {
    font-size: 22px;
    font-weight: 700;
    cursor: pointer;
}
.resulthoteltlt {
    width: 100%;
    padding: 15px;
    background: #ffffff;
    border-radius: 0px;
    position: relative;
    margin-bottom: 13px;
    box-shadow: none;
}
.items {
    padding: 10px;
    width: 100%;
    height: 165px;
    background: burlywood;
}
.labeltagtxt {
    padding: 3px 19px;
    background: red;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    position: absolute;
    z-index: 3;
    top: 25px;
    right: -13px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.labeltagtxt:after {
    content: "";
    border-top: 0 solid transparent;
    border-left: 0 solid red;
    border-right: 13px solid transparent;
    border-bottom: 3px solid red;
    position: absolute;
    top: -3px;
    right: 0;
}
.labeltagtxt:before {
    content: "";
    border: 14px solid red;
    border-left: 13px solid transparent;
    position: absolute;
    top: 0px;
    left: -14px;
}
.imgBoxgllry {
    width: 100%;
    background: #ccc;
    height: 200px;
    margin-top: 3px;
    border-radius: 10px;
    position: relative;
}
.imgBoxgllry img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: .5s ease;
}
.imgBoxgllry:hover .imghtl {
    opacity: 0.3;
  }
  
.imgBoxgllry:hover button.sethoverbtn {
opacity: 1;
}
.overlayout {
    position: absolute;
    top: 43%;
    left: 28%;
}
.imgBoxgllry-imni {
    display: flex;
    align-items: center;
    margin-top: 4px;
}
button.sethoverbtn {
    font-size: 12px;
    display: none;
}
.imgBoxgllry:hover .imghtl {
    opacity: 0.3;
  }
  .imgBoxgllry:hover  button.sethoverbtn {
   display: block;
  }
  .topscrolldiv {
    position: absolute;
    bottom: 0;
    right: 10%;
    z-index: 12;
}
.itemmini-tagimg {
    width: 48px;
    height: 48px;
    background: #ccc;
    font-size: 13px;
    text-align: center;
    border-radius: 5px;
    margin: 3px;
    cursor: pointer;
}
.itemmini-tagimg img {
    width: 100%;
    height: 100%;
}
.tlt-hotel {
    font-size: 15px;
    font-weight: 600;
    color: #0b78bc;
    margin-bottom: 10px;
}
.addresshotel p {
    font-size: 13px;
    color: #999999;
}
.distancehotel {
    margin-bottom: 5px;
}
.distancehotel svg {
    font-size: 14px;
    color: #999;
}
.distancehotel span {
    font-size: 13px;
    color: #999;
}
.ratingstarhotel {
    font-size: 14px;
    color: #999;
    display: flex;
    align-items: center;
}
img.starico {
    width: 15px;
}
.ratingstarhotel ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    padding: 0;
    margin: 5px;
}
.ratingstarhotel ul li {
    margin-right: 3px;
}
.ratingtlthotel {
    font-size: 12px;
    font-weight: 500;
    color: #999;
    margin-bottom: 5px;
}
span.uponrate {
    padding: 3px 15px;
    background: #28a745;
    border-radius: 15px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 1px;
    text-align: center;
    margin-left: 9px;
}
.offernpricedtls {
    text-align: right;
    padding: 6px;
    margin-top: 50px;
}
.rateoff {
    font-size: 19px;
    color: #999999;
    text-decoration: line-through;
    font-weight: 500;
}
.rateopen {
    font-size: 22px;
    font-weight: 600;
    color: #000;
}
.btnselectroom {
    /* margin-top: 32px; */
    text-align: right;
}
.imgSliderPopup {
    min-width: 700px;
    height: 400px;
    background: #ccc;
    border-radius: 8px;
}

  /***gallery csss***/
  .thumbnail-slider-wrap {
    margin-top: 15px;
    height: 85px;
  }
  .thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
  }
  .thumbnail-slider-wrap .slick-track .slick-slide img {
    width: 70%;
  }

  .galleyheader {
    display: flex;
    align-items: center;
    width: 70%;
    margin: 0 auto;
}
.tltglry {
    flex-basis: 50%;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    padding: 12px 0;
    color: #fff;
}
.tabview {
    display: flex;
    width: 100%;
    padding: 6px 0 0 0;
    flex-basis: 80%;
}
.tabitem.active {
    border-bottom: 2px solid #fff;
    color: #fff;
    font-weight: 500;
}
.tabitem {
    font-size: 15px;
    font-weight: 400;
    margin-right: 20px;
    padding-bottom: 5px;
    padding-right: 4px;
    border-bottom: 2px solid transparent;
    color: #f1f1f1;
}
img.slickboximage {
    width: 100%;
    border-radius: 2px;
}
img.thumbnailimage {
    width: 97%;
    border-radius: 10px;
}
.slideimgbox {
    width: 70%;
    margin: 0 auto;
}
.thumbnailbox {
    width: 70%;
    margin: 0 auto;
}

.slider-box{
  
}

.galleryslider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 15px;
    background: #000000b8;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 12;
    
}
label.slickboxlabel {
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 17px;
    color: #fff;
    font-weight: 400;
    margin: 0;
    letter-spacing: .8px;
}
.btncls button svg {
    font-size: 30px;
    color: #DC354B;
}
.btncls {
    flex-basis: 20%;
    text-align: right;
}
/********/
.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 34px;
    line-height: 1;
    opacity: 1;
    color: #DC354B;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button.activecolor {
    border: 2px solid #0b78bc;
    background: #0b78bc;
    color: #fff;
}
.facilites span {
    font-size: 13px;
    font-weight: 500;
    margin-right: 9px;
    cursor: pointer;
}
.facilites span svg {
    font-size: 13px;
    margin-right: 4px;
}

.ul-activities, .ul-activities-1{
    padding: 0px 12px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
}

.ul-activities-1 li{
    margin-bottom: 10px;
}

.para-activities{
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0px;
    text-align: justify;
    
}

.tlt-hotel-1{
    font-size: 20px;
    font-weight: 600;
    color: rgb(51, 51, 51);
    margin-bottom: 0px;
}

.tlt-hotel-2{
    font-size: 15px;
    font-weight: 500;
    color: #444;
    margin-bottom: 5px;
}

.text-plan{
    font-size: 16px !important;
    color: #444 !important;
    font-weight: 600 !important;
}

.ratingstaractivity ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    padding: 0;
    margin: 5px 0px;
}

.ratingstaractivity ul li {
    margin-right: 3px;
}

/* activity search bar */

.searchbar-content {
    background: rgb(231 231 231);
    width: 100%;
    position: fixed;
    top: 80px;
    z-index: 5;
    border-bottom: 1px solid rgb(229, 229, 229);
}

.headerWrapper-txt2 {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
}

.searchmodal {
    left: 0;
    margin-top: -23px;
    box-sizing: border-box;
    border-radius: 8px;
    position: absolute;
    border: 1px solid #cfcfcf;
    border-top: none;
    top: 100%;
    width: 100%;
    overflow: hidden;
}
.searchmodal-search-box-container {
    display: flex;
    flex-direction: row;
    margin: 0;
    align-items: center;
    justify-content: center;
    padding: 7px 8px;
    background: #fff;
}

.searchmodal-search-box-container .MuiInputBase-formControl {
    width: 100%;
}

.searchmodal-search-box {
    flex: 1 1;
    outline: none;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    font-style: normal;
    letter-spacing: normal;
    border: none;
    background: transparent;
    color: #4a4a4a;
}
.searchmodal-content {
    border-top: 1px solid #cfcfcf;
    overflow: auto;
    height: auto;
    background: #fff;
    padding: 15px;
}

.searchmodal-horizontal-content-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.searchmodal-content-title {
    flex: 1 1;
    font-size: 14px;
    color: #4a4a4a;
}


.act-heading-1 {
    font-size: 28px;
    font-weight: 600;
    padding: 10px 0px 15px;
}

.farecontentBox {
    margin-bottom: 20px;
}

.calcBox {
    background: #fff;
    padding: 0px;
    border-radius: 6px;
}

.boxtlt {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.faredetailsBox {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 4px 0px;
}

.fareBox {
    flex-basis: 60%;
}

.faredetailprice {
    font-size: 16px;
    font-weight: 700;
    text-align: right;
    flex-basis: 40%;
}

.tlt-total {
    font-size: 16px;
    font-weight: 700;
}

.headerWrapper-input {
    display: flex;
    flex-direction: row;
}

.headerWrapper-left{
    flex-basis: 100%;
    justify-content: left;
    align-items: center;
    display: flex;

}

.headerWrapper-left-1 {
    flex-basis: 100%;
    justify-content: left;
    align-items: center;
    display: flex;
}

.headerWrapper-left img{
    width: 70%;
}
.headerWrapper-input h1{
    font-size: 17px;
    margin: 0;
    padding: 10px 0;
}

.headerWrapper-input h2{
    font-size: 18px;
    font-weight: 300;
}

.headerWrapper-right{
    flex-basis: 80%;
    text-align: right;
    color: #fff;
}

.headerWrapper-txt1 {
    font-size: 13px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 0px;
}


.date-activity{
    margin: 0px !important;
    padding-bottom: 20px !important;
    width: 100%;
}
.inputboxdetails input{
    font-size: 14px;
    padding: 5px 10px !important;
    height: 17px;
    background-color: #fff;
    border-radius: 3px;
}
.travellerdetails-wpr{
    padding: 15px 45px !important;
}

.tlttag {
    flex-basis: 100%;
    font-size: 14px;
    font-weight: 700;
    color: #dc354b;
    text-align: left;
}
.tlttagreset{
    flex-basis: 50%;
    font-size: 15px;
    font-weight: 500;
    color: red;
    text-align: right;
    cursor: pointer;
}
.listchecktag ul {
    padding: 0;
    list-style-type: none;
    padding: 0 7px;
    margin: 0;
}
label.labelcontrolcheck {
    width: 100%;
    margin: 0;
    /* margin-bottom: 10px; */
}


label.labelcontrolcheck span {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 2px ;
}


.cancellation span{
    display: inline-block;
    height: 45px;
    width: 45px;
    text-align: center;
    /* border: 1px solid #46143d; */
    padding: 10px 15px;
    border-radius: 50%;
    background: #fef5f6;
    margin-right: 15px;
}
.cancellation .disabled{
    background: #9e9e9e26;
}

.cancellation .disabled svg{
    color: #9e9e9e82;
}

.margin-bottomitem {
    margin-bottom: 0px;
    margin-top: 40px;
}
.cancellation svg {
    color: #dc354b;
    font-size: 16px !important;
}
.result-right{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 5px;
}

/* 
.flight-card{
width:700px;
height: 610px;
margin: 100px auto;
border-radius: 50px;
overflow:hidden;
box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
}
.flight-card-header{
height: 150px;
width: 100%;
background: linear-gradient(to bottom, #264b76 0%,#002c5f 100%);
padding: 30px 50px;
border-bottom: 7px solid #6DBE47;
position: relative;
}
.flight-logo{
height: 110px;
width:100%;

}

.flight-logo img{
width: 150px;
}
.flight-data{
height:auto;
border-top: 2px solid #3E5177;
padding-top: 1em;
display: grid;
grid-template-columns: 2fr 1fr 1fr;
text-align: left;


}

.flight-card span{
display:block;
}
.flight-card .title{
color: #838EA8;
font-size: 16px;
}
.flight-card .detail{
font-size: 18px;
padding-top: 3px;
color: white;
}



.flight-card-content{
width: 100%;
height: auto;
display: inline-block;
}
.flight-card .flight-row{
width: 100%;
padding: 50px 50px;
display:grid;
grid-template-columns: 1fr 1fr 1fr;
}
.flight-card .plane{
text-align: center;
position: relative;
}
img{
width: 90px;
}
.flight-card .plane:before{
content: '';
width: 135px;
height: 3px;
background: #efefef;
position: absolute;
top: 45px;
left: -75px;
}
.flight-card .plane:after{
content: '';
width: 135px;
height: 3px;
background-color: #efefef;
position:absolute;
top: 45px;
right: -75px;

}
.flight-from{
text-align: left;
float:left;
}
.flight-to{
text-align: right;
float:right;
}

.flight-from,.flight-to span{
display:block;
}
.from-code, .to-code{
font-size: 60px;
color: #002C5F;
font-weight:200;
}
.flight-from,.flight-to  .from-city, .to-city{
font-size: 18px;
color: #002C5F;
font-weight:400;
}

.flight-details-row{
width:100%;
display:grid;
padding: 30px 50px;
grid-template-columns: 1fr 1fr 1fr; 
}
span{
display:block;
color:#002C5F;

}
.title{
color:#6a8597;
font-size:16px;
letter-spacing: 3px;
}
.flight-details-row .detail{
margin-top:.2em;
color:#002C5F;
font-size:18px;
}
.flight-operator{
text-align:left;
float:left;
}
.flight-class{
float:right;
text-align:right;
}
.flight-number{
padding-left:80px;
} */


.itinenary-list .MuiAccordionSummary-root{
    background: #fff;
    width: 100%;
}
.viewdtlshotelcls a{
    color: #DC354B !important;
}


.itinenary-list svg.MuiSvgIcon-root {
    font-size: 16px !important;
    color: #DC354B;
    background: #fff;
    border: 1px solid #DC354B;
    margin-top: 4px;
    border-radius: 50%;
}

.itinenary-list li svg.MuiSvgIcon-root {
    font-size: 13px !important;
    color: #DC354B;
    background: #fff;
    margin-top: 4px;
    border-radius: 50%;
    border: none;
}

.hotel-list-simple{
    list-style: none;
}


.hotel-list-simple li{
   font-size: 16px;
   margin-bottom: 5px;
   padding: 0px;
}
.MuiAccordionSummary-root{
    min-height: 30px !important;
    width: 100%;
    padding: 0 16px;
}
.MuiAccordion-root.Mui-expanded{
    margin-bottom: 0;
    margin-top: 0;
    border-top: 1px solid #ddd;
}
.MuiAccordionSummary-content p h5{
    margin-bottom: 0;
}
.MuiAccordionSummary-root.Mui-expanded{
    min-height: 30px;
}

.MuiAccordion-root{
    box-shadow: none !important;
    /* background: #DC354B21; */
    margin-bottom: 0;
}
.MuiAccordionSummary-content {
    margin: 5px 0;
}
.MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0;
    margin-left: 8px;
}

.faq-section-aacordian p{
    font-size: 12px;
}

.faq-section-aacordian  svg.MuiSvgIcon-root{
    font-size: 10px !important;
}

.faq-section .MuiPaper-elevation1 {
    box-shadow:none !important;
}

.highlights-section li{
    background: #fff;
    /* border: 1px solid #e6576c; */
}

.highlights-section li svg{ 
    font-size: 16px !important;
    background: #DC354B;
    color: #fff;
    border-radius: 50%;
    margin-top: 2px;
    padding: 2px;
}

.act-select-wrapper .MuiAccordionDetails-root {
    padding-bottom: 0px;
}
.act-select-wrapper .MuiAccordionDetails-root .MuiTextField-root {
    width: 100px;
}
.accordian-main{
    width: 100%;
}

.accordian-main svg{
    font-size: 20px !important;
    border: 1px solid;
    color: #000;
}

.customized-list .MuiFormControlLabel-root{
    margin: 1px 15px;
}

.customized-list .MuiFormControlLabel-label{
   font-size: 14px;
   margin-left: 7px;
}

.customized-list .MuiAccordionSummary-root.Mui-expanded {
    /* min-height: 30px;
    background: #DC354B14; */
    background: #f2f2f2;
    width: 100%;
}
.customized-list-preview .MuiAccordionSummary-root.Mui-expanded{
    background: #fff !important;
}
.customized-list-preview .MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0 0;
    margin-left: 8px;
}
.customized-list-preview .MuiFilledInput-root{
    width: 100px;
}
.finger-icon{
    color: #DC354B;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    font-size: 16px !important;
    margin-top: -5px;
    margin-right: 6px;
}

.d-grid{
    display: grid;
}

.sticky-parent{
    justify-content: space-around;
    overflow: visible;
}

.sticky-child{
    position: sticky;
    position: -webkit-sticky;
    top: 75px;
}

.selectactivity-container .sticky-child{
    position: sticky;
    position: -webkit-sticky;
    top: 125px;
}
.hotelrow {
    margin: 25px 0;
    padding: 15px 10px 25px;
    background: #fff;
    border-radius: 12px;
}

.skeleton .card-img{
    box-shadow: 0 0px 3px 1px rgb(0 0 0 / 10%);
    padding: 20px;
}

.no-result-box {
    /* box-shadow: 2px 0px 6px 0px #ddd; */
    padding: 20px 20px;
    text-align: center;
    margin-bottom: 30px;
    border-radius: 10px;
}
.no-result-box img{
    width: 30%;
}
.no-result-box  .head-1{
}

.no-result-box  .head-2{
    font-size: 24px;
    font-weight: 700;
    color: #DC354B;
    margin-top: -20px;

}

/* .para-activities-div span{
    width: 100%;
  line-height: 1.2em;
  height: 3.6em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-bottom: 0;
} */

.text-show-less {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: calc(2 * 1.5 * 14px);
}

.text-show-more {
    -webkit-line-clamp: unset;
    max-height: none;
}
.para-activities p{
    margin: 0;
}
.btn-show{
    background: transparent !important;
    font-size: 11px;
    color: #DC354B;
    padding: 0px 0px !important;
    float: right;
    min-width: -webkit-max-content;
    min-width: max-content;
    display: inline-block;
    border: none;
}

.MuiSnackbar-root{
    left: auto !important;
    z-index: 99;
}

.custom-modal .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
}

.custom-modal .modal-footer button{
    font-size: 11px;
    padding: 4px 20px;
    font-weight: 600;
    margin-right: 10px;
    border-radius: 20px;
    color: #fff;
}


.preview-modal .modal-dialog {
    max-width: 1200px;
    margin: 1.75rem auto;
}

.preview-modal .modal-footer button{
    font-size: 12px;
    padding: 10px 20px;
    font-weight: 600;
}

.success-dialog h1 {
    color: #008000;
    /* font-family: "Nunito Sans", "Helvetica Neue", sans-serif; */
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 10px;
  }
.success-dialog  p {
    font-size:20px;
    margin: 0;
  }
.success-dialog i {
  color: #9ABC66;
  font-size: 100px;
}
.success-dialog .card {
  background: white;
  padding: 20px 60px 50px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 50px auto;
  width: 100%;
  text-align: center;
}
/* 
.success-dialog .card-body{
    border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;
} */
  
  .alert{
    background-color: #fff;
    font-size: 25px;
    font-family: sans-serif;
    text-align: center;
    /* height: 100px; */
    padding-top: 50px;
    position: relative;
    /* border: 1px solid #efefda; */
    border-radius: 2%;
    margin: 0;
  }

    .add-flight-btn p{
        color: #e9798a;
        font-size: 12px;
        padding-top: 6px;
        margin-bottom: 5px;
    }

    .add-flight-btn button{
        font-size: 13px;
        padding: 6px 10px;
        font-weight: 500;
        background: #DC354B !important;
        color: #fff;
        border: 1px solid #DC354B;
        border-radius:4px;
    }
  
  .alert::before{
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 100%;
    /* inset: 20px 0px 0px 100px; */
    font-size: 60px;
    /* line-height: 100px; */
    border: 5px solid gray;
    -webkit-animation-name: reveal;
            animation-name: reveal;
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    display: block;
    margin: auto;
  }
  
  .alert>.alert-body{
    opacity:0;
    -webkit-animation-name: reveal-message;
            animation-name: reveal-message;
    -webkit-animation-duration:1s;
            animation-duration:1s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-delay:1.5s;
            animation-delay:1.5s;
    -webkit-animation-fill-mode:forwards;
            animation-fill-mode:forwards;
  }
  
  @-webkit-keyframes reveal-message{
    from{
      opacity:0;
    }
    to{
      opacity:1;
    }
  }
  
  @keyframes reveal-message{
    from{
      opacity:0;
    }
    to{
      opacity:1;
    }
  }
  
  .success{
    color:green;
  }
  
  .success::before{
    content: '✓';
    background-color: #eff;
    box-shadow: 0px 0px 12px 7px rgba(200,255,150,0.8) inset;
    border : 5px solid green;
  }
  
  .error{
    color: red;
  }
  
  .error::before{
    content: '✗';
    background-color: #fef;
    box-shadow: 0px 0px 12px 7px rgba(255,200,150,0.8) inset;
    border : 5px solid red;
  }
  
  @-webkit-keyframes reveal {
    0%{
      border: 5px solid transparent;
      color: transparent;
      box-shadow: 0px 0px 12px 7px rgba(255,250,250,0.8) inset;
      -webkit-transform: rotate(1000deg);
              transform: rotate(1000deg);
    }
    25% {
      border-top:5px solid gray;
      color: transparent;
      box-shadow: 0px 0px 17px 10px rgba(255,250,250,0.8) inset;
      }
    50%{
      border-right: 5px solid gray;
      border-left : 5px solid gray;
      color:transparent;
      box-shadow: 0px 0px 17px 10px rgba(200,200,200,0.8) inset;
    }
    75% {
      border-bottom: 5px solid gray;
      color:gray;
      box-shadow: 0px 0px 12px 7px rgba(200,200,200,0.8) inset;
      }
    100%{
      border: 5px solid gray;
      box-shadow: 0px 0px 12px 7px rgba(200,200,200,0.8) inset;
    }
  }
  
  @keyframes reveal {
    0%{
      border: 5px solid transparent;
      color: transparent;
      box-shadow: 0px 0px 12px 7px rgba(255,250,250,0.8) inset;
      -webkit-transform: rotate(1000deg);
              transform: rotate(1000deg);
    }
    25% {
      border-top:5px solid gray;
      color: transparent;
      box-shadow: 0px 0px 17px 10px rgba(255,250,250,0.8) inset;
      }
    50%{
      border-right: 5px solid gray;
      border-left : 5px solid gray;
      color:transparent;
      box-shadow: 0px 0px 17px 10px rgba(200,200,200,0.8) inset;
    }
    75% {
      border-bottom: 5px solid gray;
      color:gray;
      box-shadow: 0px 0px 12px 7px rgba(200,200,200,0.8) inset;
      }
    100%{
      border: 5px solid gray;
      box-shadow: 0px 0px 12px 7px rgba(200,200,200,0.8) inset;
    }
  }

.preview-flight-details .acti-flight-list {
    height: auto;
    overflow: hidden;
    padding: 0px;
    border: none;
}
.preview-flight-details .bgflightdetailscard{
    margin: 0;
    padding: 0;
}

.activity-preview .inputFieldBox {
    display: flex;
    margin-bottom: 10px;
    background: #F2F5F9;
    padding: 10px 0px;
    border-radius: 12px;
}

.activity-preview .sticky-child {
    position: sticky;
    position: -webkit-sticky;
    top: 115px;
}
.activity-preview .tlt-input-traveller {
    font-size: 11.5px;
    margin-bottom: 0px;
    font-weight: 500;
    color: #444;
}

.activity-preview .setinputBox p{
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 14px;
}

.evt-date{
    font-size: 12px;
    position: absolute;
    top: -5px;
    right: 5px;
}
.evt-date svg{
    font-size: 15px;
    margin: 2px -1px;
    color: #9d8152;
}
.evt-date p{
    margin-bottom: 0;
    font-size: 10px;
}

.evt-date .para-2{
    margin-bottom: 0px;
    font-size: 14px;
    margin-top: -4px;
    font-weight: 600 !important;
    /* color: #46143e; */
}

#filled-number{
    padding-top: 25px !important;
    padding-right: 12px;
    padding-bottom: 8px;
    padding-left: 12px;
}
.rateopen-strike{
    font-size: 13px;
}
.rateopen-strike strike{
    color: #9e9e9e;
}
.rateopen-strike span{
    font-size: 10px;
    color: orangered;
}

/* about section */
.category-section{
    display: flex;
    flex-direction: row;
    /* padding: 0 8%; */
}

.category-section .category-box{
    position: relative;
    height: 130px;
    flex-basis: 33%;
    width: 33%;
    padding: 10px 5px;
    border-radius: 8px;
    margin: 0 20px;
    background-color: #dd00100a;
}
.category-box-selected {
    border: 1px solid #DC354B;
}
.category-box h1{
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    margin: 10px
}
.category-box p{
    font-size: 12px;
    font-weight: 400;
    text-align: center;
}

.category-section .text-bold{
    text-align: center;
}
.category-input-box{
    text-align: center;
    margin: 10px 0 0px;
}
.category-input-box button{
    font-size: 13px;
    padding: 5px 15px;
    font-weight: 600;
    background: #DC354B !important;
    color: #fff;
    border: 1px solid #DC354B;
    border-radius: 4px;
}

.galleryslider{
    margin-top: 2% !important;
}
.image-gallery-slides img {
    border-radius: 16px;
}
    
.tabboxhotel .cancellation {
    border: 1px solid #fcdee2;
    border-radius: 50px;
}

.tabboxhotel .cancellation p{
    padding: 12px 0;
    font-size: 15px;
    font-weight: 400;
    color: #000;
}

@media (max-width: 786px){
    .cancellation span{
        height: 37px;
        width: 37px;
        padding: 5px 10px;
        margin-right: 10px;
    }
    .tabboxhotel .cancellation p{
        padding: 7px 0;
        font-size: 12px;
        font-weight: 400;
        color: #000;
    }
    .category-section {
        display: flex;
        flex-direction: column;
    }
    .category-section .category-box{
        position: relative;
        height: 130px;
        flex-basis: 100%;
        width: 100%;
        padding: 10px 5px;
        border-radius: 8px;
        margin: 0px 0 15px;
        background-color: #dd00100a;
    }
    .flitercolumstick{
        display: none;
        position: relative;
    }
    .monfilter{
        display: block !important;
    }
    .d-block-xs {
        display: block !important;
    }
    .result-right {
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 5px;
        padding: 0 20px;
    }

    .btnselectroom {
        padding: 0px 15px;
    }
    .offernpricedtls {
        text-align: left;
        padding: 15px 15px 5px;
    }
    .headerWrapper-input h1 {
        font-size: 15px;
    }
    .headerWrapper-input h2 {
        font-size: 14px;
    }
    .headerWrapper-txt1 {
        font-size: 10px;
    }
    .tlt-hotel-1 {
        font-size: 17px;
        margin-top: 10px;
    }
    .tlt-hotel-2 {
        font-size: 12px;
    }
    .no-result-box .head-2 {
        font-size: 16px;
        margin-top: -10px;
    }

    .searchbar-content {
        top: 63px !important;
    }

    .evt-date{
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
        padding: 15px 15px 0px;
    }
    .custom-modal .modal-dialog {
        max-width: 100%;
        margin: 1.75rem 10px;
    }
}

.filter-content-ec {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    margin-top: 0;
    background-color: #fff;
}
.boxdivition {
    padding: 15px;
    margin: 15px 0;
    background: #fff;
    border-radius: 8px;
    border: none;
}

.tltrange-filter {
    flex-basis: 50%;
    font-size: 16px;
    font-weight: 600;
}

.tltrangereset {
    font-size: 14px;
    font-weight: 500;
    color: tomato;
    width: 55%;
    cursor: pointer;
    text-align: right;
}

.MuiFormControl-root{
    width: 100%;
}

.depositdate-picker input{
    font-size: 13px;
    padding: 8px;
    width: 100%;
}

.report-tlt{
    font-size: 14px;
}

.fc-button-primary{
    background: #DC354B !important;
    border-color: #DC354B !important;
    outline: none !important;
}
.fc .fc-button:focus{
    box-shadow: none !important;
}

.fc-event-main span{
    font-size: 11px;
    padding: 1px 4px !important;
    margin-bottom: 2px;
    cursor: pointer;
}

.fc-daygrid-event-harness{
    margin-top: 2px !important;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0.5em !important;
}

.label-detector{
    position: fixed;
    top: 25%;
    right: 0;
    z-index: 99;
}
.label-detector ul{
    list-style: none;
    padding: 0px;
}

.label-detector ul li {
    list-style: none;
    padding: 3px 8px;
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: 500;
    /* width: max-content;
    margin-right: 15px; */
    border-radius: 2px;
}

.label-detector ul li span{
    height: 5px;
    width: 15px;
    display: inline-block;
    margin-right: 3px;
}
/* cricket label */
.label-cricket {
    background: #c4513f3d;
    /* color: #c4513f; */
}
.label-cricket span{
    border: 4px solid #C4513F;
}

/* Golf label */
.label-golf {
    background: #4F86A43d;
    /* color: #4F86A4; */
}
.label-golf span{
    border: 4px solid #4F86A4;
}

/* football label */
.label-football {
    background: #1F85393d;
    /* color: #1F8539; */
}
.label-football span{
    border: 4px solid #1F8539;
}

/* tennis label */
.label-tennis {
    background: #485C2C3d;
    /* color: #485C2C; */
}
.label-tennis span{
    border: 4px solid #485C2C;
}

/* formula label */
.label-formula {
    background: #837D783d;
    /* color: #837D78; */
}
.label-formula span{
    border: 4px solid #837D78;
}

/* music label */
.label-music {
    background: #02AFD43d;
    /* color: #02AFD4; */
}
.label-music span{
    border: 4px solid #02AFD4;
}

.filter-content-ec {
    display: block;
}
.filter-content-res, .filter-btn-xs{
    display: none !important;
}
@media (max-width: 786px) {
    .filter-ec{
        padding-right: 0px;
    }
    .fc .fc-button{
        padding: 2px 7px !important;
    }
}

@media (max-width: 900px){
    .filter-content-res, .filter-btn-xs {
        display: block !important;
        margin-bottom: 20px;
    }
    .filter-content-ec {
        display: none;
    }
    .label-detector {
        position: -webkit-sticky;
        position: sticky;
        top: 52px;
        right: 0;
        z-index: 99;
        padding-top: 0pc;
        background: #fff;
        padding: 10px;
        margin-bottom: 10px;
    }
    .label-detector ul {
        list-style: none;
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0;
    }
    .label-detector ul li{
        margin-right: 10px;
    }

}
.fc-view-harness-active{
    height: 800px !important;
}
.about-us h1 {
    font-size: 20px;
}
.about-us {
    background: #fff;
    border-radius: 8px;
}
.about-us p {
    font-size: 14.5px;
    color: #444;
    display: flex;

}
.aboutimgbanner img {
    width: 100%;
    border-radius: 8px;
}
.aboutimgbanner {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
}
.about-us h3{
    font-size: 19px;
}
.about-us ul li {
    font-size: 14.5px;
    color: #828080;
}
.tlt-docs h1 {
    font-size: 25px;
    margin: 0 18px;
    margin-top: 10px;
}
.linksset {
    padding: 5px 30px;
    width: 1240px;
    margin: auto;
}
a.linkdiv-set {
    font-size: 14px;
    font-weight: 500;
}
p.linkdiv-set {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}
a.linkdiv-set {
    font-size: 12px;
    font-weight: 500;
    color: #fff !important;
}
.tlt-docs {
    background: linear-gradient(to right, #bb9349, #bb93497d );
    width: 100%;
    position: fixed;
    top: 80px;
    z-index: 5;
}
.privacy-box{
    position: absolute;
    margin-top: -24%;
    margin-left: 0%;
    text-align: center;
    align-items: center;
    background: #f0f8ff94;
}
.privacy-txt{
    font-size: 2rem !important;
    padding: 20px 30px;
    font-family: monospace;
}
.privacyimgbanner img{
    width: 100%;
    height: 250px;
    border-radius: 5px;
}   
.pp-para h1 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
}

.pp-para h2 {
    font-size: 15px;
    font-weight: 600;
    color: #000;
}
.pp-para-medius p{
    font-size: 16.5px;
    color: #444;
}
.pp-para b{
    color: #000;
    font-weight: 600;
    margin-right: 5px;
}
.pp-rightcontainer b{
    font-weight: 600;
}
.text-xs{
    font-size: 12px !important;
}

.searchbar-content {
    background: rgb(231 231 231);
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
    border-bottom: 1px solid rgb(229, 229, 229);
    /* padding: 7px 0; */
}

.headerWrapper-input h4 {
    font-size: 17px;
    margin: 0;
    padding: 10px 0;
}

/* .activity-tab{
    margin: 40px;
} */
.MuiAccordion-root{
   box-shadow: none !important;
}

.faq-section-aacordian p{
   font-size: 12px;
   margin-left: 10px;
}

.faq-section-aacordian  svg.MuiSvgIcon-root{
   font-size: 10px !important;
}
  
.collapseBox {
    padding: 10px 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 6px -2px rgb(107 105 105);
}
.boxcontainent {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 20px;
    background: #fff;
    border-radius: 5px;
}
.departndate {
    padding: 11px;
    padding-left: 30px;
    background: #EC5E71;
    color: #fff;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.tlt-depart {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: .5px;
}
.date-depart {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .5px;
    margin-top: -4px;
}
.depart-tlt {
    padding: 15px;
}
.btn-collapse {
    /* padding-top: 10px; */
}
.departcode {
    font-size: 20px;
    font-weight: 700;
}
.departcodedetails {
}
.departcodedetails span {
    font-size: 13px;
    font-weight: 600;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #ccc;
}
.btnCollapsedsect {
    align-self: center;
}
.btn-collapse button {
    padding: 0px !important;
    font-size: 14px!important;
    text-transform: capitalize!important;
    border: none !important;
    font-weight: 600;
}
.btn-collapse button:hover{
    padding: 0px !important;
    background-color: transparent !important;
    border: none !important;

}
.imgdepartbooking {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.departflightname {
    font-size: 13px;
    font-weight: 600;
}
.departflightnumber {
    font-size: 13px;
    font-weight: 500;
}
.departimgminifly {
    margin-right: 20px;
}
.flexset-depart-inside {
    display: flex;
    align-items: center;
    text-align: center;
}
.definedepartpwidth {
    flex-basis: 50%;
}
.departarrowbox {
    flex-basis: 30%;
}
.departdefineroundtripwidth {
    width: 50%;
}
.departflightminitime {
    font-size: 18px;
    font-weight: 700;
}
.departflightminidata {
    font-size: 10px;
    font-weight: 800;
}
.departflightpara p {
    font-size: 13px;
    font-weight: 500;
    color: #999;
}
.CollapseDetails {
    background: #fff;
    padding: 15px;
    box-shadow: 0px 1px 5px -1px rgb(0 0 0 / 40%);
}
.alignmentBox {
    align-self: center;
}
span.boldtxt {
    font-size: 12px;
    font-weight: 500;
}
span.txtlight {
    font-size: 13px;
    font-weight: 600;
}
.baggagedepart{
    padding: 5px 0;
    border-top: 1.8px solid #ccc;
}
/********traveller details*************/
.inputboxdetails {

}
.inputFieldBox {
    display: flex;
    margin-bottom: 2px;
}
.tlt-traveller {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}
.Subtlt-traveller {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
}
.tlt-input-traveller {
    font-size: 12.5px;
    margin-bottom: 0px;
    font-weight: 500;
    color: #000;
}
.pd-left {
    margin-left: 12px!important;
}
.travellerdetailsBox {
    background: #fff;
    box-shadow: none;
    border-radius: 0px;
    padding: 15px 30px;
}
label.labelcheckradio {
    margin: 0!important;
}
.inputboxdetails {
    width: 100%;
}
.inputdetails {
    width: 80%;
    margin-left: 5px!important;
}
.countrycode {
    width: 150px!important;
}
/***********contact details************/
.tlt-contactdetails {
    font-size: 20px;
    font-weight: 600;
    padding: 16px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}
/*******select seat*************/
.tltseat {
    font-size: 20px;
    font-weight: 500;
}
.tltseat {
    font-size: 20px;
    font-weight: 500;
    padding: 8px 15px;
    border-bottom: 1px solid #ccc;
}
.prst-data {
    display: flex;
    align-items: center;
}
.flightsetseat {
    flex-basis: 80%;
}
.tlt-fly {
    font-size: 18px;
    font-weight: 600;
}
.subtlt-fly {
    font-size: 15px;
    font-weight: 500;
}
.seat-selectionBox {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 5px -1px rgb(0 0 0 / 40%);
}
/****ssr details*****/
.ssr-tlt {
    padding: 15px 15px;
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
}
.tlt-ssr-mini {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
}
.ssrdetailsbox {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 5px -1px rgb(0 0 0 / 40%);
    padding-bottom: 10px;
}
.tablesetssrdetails {
    background: #EC5E71;
}
.tablesetssrdetails th{
    color: #fff !important;
}
.dropdowntxtbox {
    display: flex;
}
.labeltxt-mini {
    font-size: 12px;
    font-weight: 500;
}
.lfield {
    width: 100%;
}
.rfield {
    width: 100%;
    margin-bottom: 12px;
}
.dropdownssrinput {
    width: 100%;
}
.tltssrbox {
 font-size: 15px;
 font-weight: 600;
}
.labeltxt {
    font-size: 16px;
    font-weight: 600;
}
.tlt-txt {
    font-size: 16px;
    font-weight: 600;
}
.tlt-txt-stag {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 500;
    color: red;
}
.dropdownssrinput {
    width: 100%;
}
button.seatbtnpopup {
    font-size: 12px;
    font-weight: 600;
}
.dropdowntxtbox {
    margin-top: 15px;
    margin-bottom: 10px;
}
.flexBoxdiv {
    display: flex;
    align-items: center;
}
.tableContainerbox {
}
th.thcell {
    text-align: -webkit-center;
    font-weight: 600;
    font-size: 12px;
    padding: 3px 10px;
    border: 1px solid #ccc;
    text-transform: uppercase;
}
td.tdcell {
    text-align: -webkit-center;
    font-size: 14px;
    padding: 4px 10px;
    border: 1px solid #ccc;
    text-transform: capitalize;

}
/********gst business ***********/
.gsttxt {
    padding: 15px;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #ccc;
    margin-bottom: 5px;
}
.notedtxt {
    font-size: 14px;
    font-weight: 500;
    color: brown;
    margin-bottom: 5px;
}
.inputgsttlt{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 2px;
}
.gstinputfield input {
    /* padding: 8px!important; */
}
.gstRowblock {
    /* padding: 15px 15px 0px 15px; */
}
.gstbox {
    padding-bottom: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 5px -1px rgb(0 0 0 / 40%);
}

.seatCol {
    width: 100%;
    height: 40vh;
    background-color: #ccc;
}
.seatCol1 {
    width: 100%;
    height: 40vh;
    background-color: antiquewhite;
}
.seatCol2 {
    width: 100%;
    height: 40vh;
    background-color: yellowgreen;
}
.dailogBox-tlt {
    display: flex;
    align-items: center;
}
.main-tlt {
    font-size: 18px;
    font-weight: 500;
    flex-basis: 20%;
}
/* .popupboxseatset {
    min-width: 1209px!important;
} */
.dialogimgminifly {
    margin-right: 7px;
}
.dialogimgminifly img {
    width: 39px;
}
.dailogflightname {
    font-size: 14px;
    font-weight: 500;
}
.dailogflightnumber {
    font-size: 14px;
    font-weight: 500;
}
.flightorientation {
    font-size: 14px;
    font-weight: 500;
    color: #676464;
}
.zerogapcoldailog {
    padding: 0!important;
}
.passenger-seat {
    background: aliceblue;
}
.tblrow {
    display: flex;
    align-items: center;
    width: 100%;
}
.tblitems {
    flex-basis: 50%;
    padding: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}
.tblcolitems {
    flex: 50% 1;
    padding: 6px;
    font-size: 14px;
    color: #5a5858;
}
.tblrowitems {
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #ccc;
}
.tblrowitemsactive {
    background-color: #3c9c408f;
}
.tblrow {
    background: antiquewhite;
    border-radius: 5px;
}
.passenger-seat {
    margin-bottom: 15px;
}
.tlt-para {
    font-size: 13px;
    font-weight: 500;
    color: red;
}
.notify-para p {
    font-size: 13px;
    color: #000;
    text-align: justify;
    letter-spacing: -0.1px;
    word-spacing: -1px;
    word-break: break-word;
}
.seatstatus {
    display: flex;
    text-align: center;
    flex: auto;
}
.close-ico {
    width: 30px;
    height: 25px;
    background: #ccc;
    color: #fff;
    margin: 5px 5px 5px 0;
    border-radius: 5px;
}
.check-ico {
    width: 30px;
    height: 25px;
    background: #28a745;
    color: #fff;
    margin: 5px;
    border-radius: 5px;
}
.selectcolor {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    margin-right: 5px;
}
/* .seatstatus {
    margin-bottom: 15px;
} */
.seatlayout {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.occupiedseat {
    width: 40px;
    height: 40px;
    text-align: center;
    align-items: center;
    border: 1px solid #ccc;
    font-size: 19px;
    padding: 5px;
    margin: 3px;
    color: #000;
    background: #ccc;
}
.selectedseat {
    width: 40px;
    height: 40px;
    text-align: center;
    align-items: center;
    border: 1px solid #ccc;
    font-size: 19px;
    padding: 5px;
    margin: 3px;
    background-color: #28a745;
    color: #fff;
}
.availableseat {
    width: 40px;
    height: 40px;
    text-align: center;
    align-items: center;
    border: 1px solid #ccc;
    font-size: 12px;
    padding: 9px;
    font-weight: 500;
    margin: 3px;
    background: beige;
    color: #000;
    cursor: pointer;
}

.columBoxRow {
    margin-left: 10%!important;
}
.coulmSeatview {
    margin: 0px;
}
.flightorientation span svg {
    font-size: 30px!important;
}
.tabs button {
    padding: 5px;
    font-size: 15px;
    text-align: left!important;
}
button.tabsset {
    font-size: 15px;
    font-weight: 500;
    text-align: left!important;
    margin-bottom: 3px;
    background: #ccc;
    border-radius: 5px;
}
.paneldivbox {
    flex-basis: 80%;
    padding-left: 45px;
}
.boxoverlay {
    position: relative;
    width: 100%;
}
.overlayposotion {
    position: absolute;
    background: #f2f2f2;
    padding: 3px 20px;
    border-radius: 30px;
    top: 2px;
    color: red;
    left: 30%;
    font-size: 10px;
    font-weight: 500;
}
/****************/

.setsector {
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color: #EC5E71;
    margin-bottom: 8px;
    margin-left: -15px;
    padding: 7px 19px;
    color: #fff;
    background: #EC5E71;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
.datesethtd {
    margin-left: 6px;
    color: #808080;
}
.arrowsect img {
    width: 60px;
    padding: 8px;
}
.tltheadbx {
    font-size: 14px;
    font-weight: 500;
}
.itextbox {
    width: 100%;
    padding: 10px;
    background-color: #999;
}
.paxtltnm {
    font-size: 14px;
    font-weight: 500;

}
.txtheadpaxrow {
    background: #fc9e15;
    color: #000;
    padding: 5px 0px;
}
.dtatrowdata {
    padding: 8px 0;
}
.meal-list{
    width: 500px !important;
    white-space: normal !important;
    font-size: 16px !important;
}

.Main-content-header {
    z-index: 9 !important;
}

.MuiMenu-list li{
    display: flex !important;
    padding: 6px 16px !important;
}

.travellerdetailsBox .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding: 0px !important;
    line-height: 23px;
    border: 1px solid #ddd !important;
    background: #fff;
}

textarea {
    border: 1px solid #ddd !important;
}

.travellerdetailsBox .MuiSelect-select {
    padding: 7px !important;
    font-size: 12px;
}


.switch-offlinemode{
    margin: -5px;
    margin-right: 0px !important;
}

.label-offlinemode{
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 600;
}

/* New Ui */

.rightBoxContainer{
    margin: 20px 0px;
    background: #fff;
    padding: 15px 15px 0px;
    border-radius: 6px;
}

.depart-tlt-top  .departcode {
    font-size: 18px;
    font-weight: 700;
}

.summarycontainer{
    padding: 0px 15px 10px;
}
.summarycontainer .arrowbox {
    width: 150px;
    margin: 3px;
    text-align: center;
}

.summarycontainer .define-stop {
    font-size: 11px;
    font-weight: 500;
    color: #000;
    padding-bottom: 8px;
}
.baggageBox{
    display: flex;
}
.items-head {
    margin-right: 20px;
}

.summary-date {
    font-size: 12px;
    font-weight: 600;
}
.summary-time {
    font-size: 12px;
    font-weight: 800;
}


.inputBoxgst .gstinputfield{
    width: 100%;
}

.mealBtn button{
    display: block;
    padding: 5px !important;
}

.tltname-meal{
    margin-bottom: 5px;
    font-size: 14px;
    color: #000;
    text-transform: uppercase;
    font-weight: 600;
}

.sectorToggleBtn{
    background: aliceblue !important;
    margin-bottom: 10px !important;
    width: -webkit-max-content;
    width: max-content;
    margin-right: 15px;
}

.sectorToggleBtn:hover{
    color: #fff !important;
    background: #EC5E71 !important;
}
.sectorToggleBtnActive{
    color: #fff !important;
    background: #EC5E71 !important;
    margin-bottom: 10px !important;
    width: -webkit-max-content;
    width: max-content;
    margin-right: 15px;
}

.mealBox{
    background: #f8f9fa;
    padding: 10px 20px;
}

.inputboxremark input{
    height: 50px !important;
}

.Mui-error{
    margin-left: 0px;
}

.define-timecalc {
    font-size: 11px;
    font-weight: 500;
    padding-top: 8px;
}
.arrow-img {
    position: relative;
}
.arrow-line{
    border-bottom: 1px dotted #6c757d;
    display: block;
    height: 1px;
    width: 45%;
    margin: auto;
}

.baggage-img img {
    width: 75%;
    padding: 5px;
}
.meal-img img{
    width: 65%;
}
.baggage-row {
    background: #fff;
    padding: 10px 0px;
    margin-bottom: 10px;
}
.baggage-heading li{
    font-size: 14px;
    padding: 0px;
    list-style: none;
}
.meal-price {
    font-size: 14px;
    font-weight: 700;
    display: block;
}

.baggage-btn .MuiButton-root {
    min-width: 30px;
}
.add-on-head{
    padding: 10px 0px;
    background: #fff;
    margin-bottom: 16px;
}
.ttl-cityname{
    font-size: 18px;
    font-weight: 500;
}
.meal-count{
    font-size: 13px;
}

.container-approval .container{
    border: 1px solid #ddd;
    height: 200px;
}

.message {
	display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    height: 180px;
    justify-content: center;
    font-family: monospace;
    font-size: 14px;
}

.message__text {
	margin-top: 12px;
	text-align: center;
}

.shortcut {
	display: flex;
	align-items: center;
}

.shortcut__key {
	padding: 6px;
	border: 2px solid var(--color-light);
	border-radius: 6px;
}

.shortcut__sign {
	margin: 0 6px;
}

.image {
	max-width: 100%;
	max-height: 100%;
    height: 200px;
    object-fit: contain;
}

.hidden {
	display: none;
}

.btn-remove-img, .btn-remove-img:hover{
    position: absolute;
    right: 5px;
    background: transparent;
    border: none;
    color: #000;
    padding: 0px;
    top: -12px;
}

.footeraddress {
    font-size: 16px;
    width: 100%;
    flex-basis: 27%;
    color: #ccc;
    height: auto !important;
}
.footer {
    background: rgb(51, 51, 56);
    color: rgb(127, 127, 127);
    padding: 20px 30px 20px;

}
.footerimg img {
    width: 60%;
    /* background: #fff; */
}
.footeraddress p, .footeraddress p a {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 10px;
    color: #ccc !important;
}

.footeraddress p:hover, .footeraddress p a:hover {
    color: #fff !important;
}
.copyright {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    width: 100%;
}
.socialicons {
    width: 100%;
    display: flex;
    border: none !important;
}
.linklist p {
    font-size: 14px;
    padding-right: 35px;
    text-align: justify;
}
.linklistul {
    flex-basis: 25%;
    height: auto !important;
    padding-left: 20px;
}
.linklistul ul {
    list-style-type: none;
    font-size: 13px;
    margin-bottom: 0;
}
.linklistul ul li {
    line-height: 2;
}
.linklistul ul li a{
    color: #ccc !important;
}
.socialicons a svg {
    font-size: 18px !important;
    color: #000;
    margin: 0px !important;
}
.footerimg {
    display: block;
}
.footerlogowrp{
    /* margin: auto; */
}
.linklist ul li {
    line-height: 2;
}

.flexboxlink {
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-top: 3px solid #fff;
}
.listlinkitemsbox {
    display: flex;
    max-width: 100% !important;
}
.linklist {
    margin-top: 35px;
}

.footer .container-fluid{
    max-width: 1220px;
}
.socialicons a{
    font-weight: 400!important;
    background: #ccc;
    font-size: 13px;
    color: rgb(51, 51, 56) !important;
    display: inline-flex;
    margin-right: 10px !important;
    padding: 10px !important;
    border-radius: 50%;
    margin-top: 20px;
}
.socialicons a:hover{
    background: #fff;
}

@media (max-width:786px) {
    .linklistul{
        padding-left: 0;
    }

    .footerimg img {
        width: 50% !important;
    }
    .listlinkitemsbox {
        display: flex ;
        margin-top: 0px !important;
    }
    .footeraddress p {
        margin-bottom: 10px !important;
        margin-top: 0px !important;
    }
}

@media (max-width:600px) {
    .listlinkitemsbox {
        display: block ;
    }
    .socialicons a{
        font-size: 13px;
        margin-left: 10px !important;
        margin-right: 0px !important;
        padding: 10px !important;
        border-radius: 50%;
        margin-top: 0px;
    }
}
.navbar-items {
    width: 60%;
}
.logo-flex{
    display: flex;
    text-align: center;
    align-items: center;
    
}
.logo-flex p{
    margin-left: 10px;
}
.navbar{
    padding: 0px 0px !important;
}
nav ul.items-navbar {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    list-style-type: none;
}
.navbar-expand-lg{
justify-content: space-between !important;
}
.navbar-expand-lg .navbar-nav .nav-link {
    margin-left: 12px;
}

ul.items-navbar li a {
    font-size: 15px;
    color: #000;
    text-decoration: none;
    font-weight: 500;
}

ul.items-navbar li {
    margin: 0 7px;
    padding: 10px;
    text-align: center;
}

.user_section_notification {
    width: 100%;
    text-align: end;
    margin-top: 25px;
    position: relative;
}

.flight-ico {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.itw-logo {
    width: 140px;
    background: #fff;
    margin-right: 5px;
}

.NavbarHeader{
    margin: 0 5px;
}

.logo {
    text-align: center;
    padding-top: 0px;
    display: flex;
    flex-basis: 10%;
    width: 25%;
}

.headerBox {
    background-color: #fff!important;
    border-bottom: 3px solid #fc9e15;
}

ul.items-navbar li a span svg {
    font-size: 20px;
}

svg.MuiSvgIcon-root {
    font-size: 20px;
}

button.btn-drop {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
}

button.btn-notify {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 15px;
}

.top-header-dropdown {
 
}
.balance-nav{
    width: 100%;
    display: flex;
}
.balanced-view {
    padding: 0px 10px;
    font-size: 13px;
    font-weight: 500;
    width: auto;
    color: #fc9e15;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #ccc;
}

.dropdown-profile {
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    position: relative;
}

.notify-cnt {
    width: 100%;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 0px 6px rgb(0 0 0 / 30%);
}

.notify_show {
    display: block;
}

.notify_hide {
    display: none;
}

.notify-items {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding: 7px 10px;
    cursor: pointer;
}

.notify-items:hover {
    background: beige;
}

.notifycontenthover {
    position: absolute;
    width: 300px;
    right: 0;
    z-index: 9;
}

.higlighttxt {
    font-size: 13px;
    padding: 0 5px;
    white-space: nowrap;
    width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Blurtxt {
    font-size: 11px;
    padding: 0 5px;
}

.notify-viewmore {
    padding: 15px;
    text-align: center;
}

.viewmore {
    font-size: 15px;
    color: #1675b8;
}

.blc-list {
    background: #fff;
    padding: 5px;
    box-shadow: 0px 2px 3px rgb(0 0 0 / 30%);
    right: 0;
    top: 34px;
    border-radius: 8px;
}

.itemblc {
    display: flex;
    align-items: center;
    padding: 7px 10px;
}

.blnc-items-l {
    flex-basis: 50%;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    text-align: start;
}

.blnc-items-r {
    flex-basis: 50%;
    text-align: end;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.blnc-items-r.balance-price {
    color: red;
}

.balance_show {
    display: block;
}

.balance_hide {
    display: none;
}

.profile_view {
    display: flex;
}

.tltprofile {
    flex-basis: 100%;
}

button.profile {
    font-size: 12px;
    padding: 4px 10px;
    text-align: left;
    width: -webkit-max-content;
    width: max-content;
    
}

.viewdropdown {
    width: 270px!important;
    right: 1!important;
    top: 7px!important;
    left: -28px!important;
    z-index: 99;
}

.profile-bg {
    background: #DC354B;
    padding: 10px;
}

.tlt-img-ico {
    width: 70px;
    height: 70px;
    border: 6px solid #9fcc3a;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    margin: 0 auto;
}

.tlt-img-ico img {
    width: 100%;
    object-fit: cover;
    text-align: center;
    border-radius: 50%;
    height: 100%;
}

.tlt-name {
    font-size: 17px;
    margin-top: 5px;
    color: #fff;
    font-weight: 500;
    text-align: right;
}

.activedate {
    font-size: 13px;
    color: #fff;
    padding-bottom: 10px;
}

.btnadmin {
    width: 100%;
    text-align: left;
    font-size: 14px;
}

.link-contact {
    display: flex;
    text-align: center;
}

.contactnumber {
    text-align: right;
    font-size: 13px;
}

.contactmail {
    text-align: right;
    font-size: 13px;
    text-transform: lowercase;
}

button.btnadminlink {
    font-size: 10px!important;
    padding: 4px 8px!important;
}

.link-contact {
    display: flex;
    text-align: center;
    align-items: center;
    padding: 4px 10px;
    background: azure;
}
.profilenChangespassword a {
    display: block;
    text-align: left;
}
ul.setlogout {
    padding: 0;
}
button.btnprofileset {
    padding: 5px 20px;
    font-size: 14px;
    text-transform: capitalize;
    width: 100%;
}
button.btnprofileset span {
    justify-content: flex-start;
}
button.changebtn {
    padding: 15px 20px !important;
    font-size: 15px;
    text-transform: capitalize;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: block !important;
    text-align: left;
    color: #000;
    font-weight: 500;
}
button.changebtn span {
    justify-content: flex-start;
}
button.linklogout {
    padding: 15px 20px !important;
    font-size: 15px;
    text-transform: capitalize;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: block !important;
    text-align: left;
    color: #000;
    font-weight: 500;
}
button.linklogout span {
    justify-content: flex-start;
}
ul a li.linklogout {
    font-size: 15px;
    color: #000;
    font-weight: 500;
    text-align: center!important;
    width: 86px;
    margin: 0 auto;
}

/***updated header css**/

.header {
    background-color: #fff;
    width: 100%;
    margin: 0;
    padding: 10px 30px;
    box-shadow: inset 0px -1px 0px #f3f3f4;
}
.Main-content-header {
    z-index: 999;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.headercol {
    padding: 0!important;
}
.navbar-light .navbar-brand{
    flex-basis: 35%;
}

.navbar-light .navbar-nav .nav-link {
    color: #000!important;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
}

.navbar-light .navbar-nav .nav-link.active {
    color: #1675b8!important;
    font-weight: 500;
}

a.navbaritems.nav-link {
    padding: 0 12px!important;
}

.dropdown-toggle::after {
    display: none!important;
}

.dropdownitem.nav-item.dropdown a {
    margin-left: 5px;
    padding: 0 5px!important;
}
.dropdownitem.nav-item.dropdown a li {
    font-size: 15px;
}

a.recharge{
    margin-right: 10px !important;
    padding: 5px !important;
    font-size: 14px;
    font-weight: 500;
}

/******updated header css******/
.rc-menu{
    z-index: 999;
}
button.rc-menu-button.upadtedmenuitems {
    outline: none;
    border: none;
    background: transparent;
    font-weight: 500;
    margin: 0 6px;
    font-size: 18px;
    display: flex;
    color: #000;
}

button.rc-menu-button.upadtedmenuitems svg.MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 5px;
    margin-top: 2px;
}

a.nav-link {
    font-weight: 400!important; 
    background: transparent;
    font-size: 13px;
}

.tlt-id{
    font-size: 13px;
    color: #fff;
    text-align: right;
}
.btnprofileset{
    color: #fff;
    text-align: right;
    margin-bottom: 0px;
    font-size: 11px;
    border: 1px solid;
    width: -webkit-max-content;
    width: max-content;
    float: right;
    padding: 2px 5px;
    margin-top: 5px;
}

.badge-custom-flight .MuiBadge-badge {
    font-size: 10px;
    height: 15px;
    background: #1675b8;
}

.topheader-left{
    flex-basis: 40%;
    justify-content: flex-start;
}
.topheader-center{
    flex-basis: 20%;
    justify-content: center;
    text-align: center;
}
.topheader-right{
    flex-basis: 40%;
    justify-content: flex-end;
}
.header .socialicons{
    text-align: center;
    justify-content: center;
    align-items: center !important;
    margin-right: 20px;
}
.header .socialicons a {
    font-weight: 400!important;
    background: #DC354B;
    font-size: 13px;
    color: #000000!important;
    display: inline-flex;
    margin-right: 10px !important;
    height: 40px;
    width: 40px;
    padding: 11px !important;
    border-radius: 50%;
    margin-top: 0 !important;
   
}

.header .socialicons a svg{
    font-size: 18px;
    color:  #fff!important;   
}

.auth-btns{
    display: flex;
}

.auth-btns .login-btn{
    width: -webkit-max-content;
    width: max-content;
    background: #DC354B !important;
    border: 1px solid #DC354B;
    color: #fff !important;
    font-weight: 400 !important;
    border-radius: 30px !important;
    padding: 6px 30px !important;
    font-size: 14px;
}

.auth-btns .signup-btn{
    width: -webkit-max-content;
    width: max-content;
    background: #fff !important;
    border: 1px solid #DC354B;
    color: #DC354B !important;
    font-weight: 400 !important;
    border-radius: 30px !important;
    padding: 6px 20px !important;
    font-size: 14px;
}

.dailogbox-container .MuiDialog-paper{
    max-width: 900px;
    overflow: hidden;
  }
  .login-dialog .MuiDialog-paper{
    max-width: 750px;
    overflow: hidden;
  }
  
  .dailogbox {
    display: flex;
    max-width: 100%;
    height: 600px;
}

.login-dialog .modal-dialog{
    max-width: 800px;
}

.dailogbox-container .modal-dialog{
    max-width: 800px;
}
  .inputsect_login {
    width: 50%;
    padding: 22px;
    position: relative;
    text-align: center;
  }
  .inputsect_signup {
    width: 100%;
    padding: 22px;
    position: relative;
    text-align: center;
  }
  .slidersect {
    width: 50%;
    height: 480px;
    background-color: #0977bc;
  }
    
    .titlefrm {
      font-size: 20px;
  }
  .btnpostion {
    position: absolute;
    width: 100%;
    left: 0;
    padding: 15px;
  }
  .Btnlogin {
    width: 100%;
  }
  .loginfrg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }
  .loginfrg span {
    font-size: 14px;
    color: #000;
    font-weight: 400;
  }
  .textBoxlogin {
    margin-top: 6%;
  }
  .textBoxsign{
    margin-top: 5%;
  }
  img.logoimg {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }

  .signup-modal img.logoimg {
    width: 100%;
    height: 650px;
    object-fit: cover;
  }
  .namewithtilebox {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
  }
  .titleuser {
    flex-basis: 18%;
  }
  .titlefirstname {
    flex-basis: 38%;
  }
  .titlelastname {
    flex-basis: 38%;
  }
  .titlecode {
    flex-basis: 20%;
  }
  .titlenumber {
    flex-basis: 77%;
  }
  .ttext {
    font-size: 13px;
    margin-top: 10px;
    color: #808080;
  }
  .btnpostion_signup {
    width: 100%;
    margin-top: 10px;
  }
  .img_with {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  .logingoogle {
    padding: 10px 0;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .logingoogle iframe{
    border: none;
   margin: auto !important;
  }

   iframe #container{
    padding: 0px !important;
    width: 95% !important;
    margin-left: 16px;
  }
  
  .titleuser .MuiFormControl-root, .titlecode .MuiFormControl-root {
    width: 100%;
    margin-top: 8px
  }
  
  .titleuser .MuiSelect-select , .titlecode .MuiSelect-select{
    padding: 9px 8px !important;
  }
  
  .inputsect_signup label, .inputsect_login label{
    display: block;
    text-align: left;
    line-height: 10px;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0px;
  }
.texttitle{
    position: relative;
    bottom: 0px !important;
    font-size: 12px;
    padding-bottom: 0px;
  }

  .loginlogo {
    width: 70px;
}

.emailfield input{
    padding: 10px;
    font-size: 14px;
}

.forgot-password span{
    font-size: 15px;
    color: #f44336 !important;
    margin-bottom: 5px !important;
    display: block;
    cursor: pointer;
}

.signup-text{
    margin-top: 10px;
}

.signup-text a{
    font-size: 13px;
}
.signup-text button{
    background: #fff;
    color: #DC354B;
    font-size: 13px;
}
.captchaSection {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}
.captchaImg {
    height: 20px;
    width: 20px;
    margin : 5px;
}

.popupHeader h6 {
    font-size: 17px;
    font-weight: 600;
}

h3.subtlt-popup {
    font-size: 15px;
    font-weight: 500;
    padding: 0 9px;
    margin: 15px 0 0;
}
.inputforgot input {
    width: 100%;
    padding: 12px;
    font-size: 13px;
}
.inputforgot {
    width: 95%;
    margin: 8px 0 8px 10px!important;
}

.smalltxt {
    padding: 8px 12px;
    font-size: 14px!important;
    color: #a5a5a5;
}

.header.sticky-parent{
    overflow: visible;
}

.header.sticky-child{
    position: sticky;
    position: -webkit-sticky;
    top: 0px !important;
    z-index: 999;
}
.box-header-clickaway{
    width: 100%;
}

.logo-side{
    display: inline-block;
    position: absolute;
    top: 16px;
}

.logo-side p{
    margin-top: -2px;
    font-size: 16px;
    /* color: #fff; */
    margin-left: 15px;
}

.logo-side img{
    width: 100px;
    border-left: 1px solid #ffffff82;
    padding-left: 14px;
    margin-top: 4px;
}
.header-icon svg{
    font-size: 35px !important;
    background-color: #fff !important;
    border: 1px solid #DC354B !important;
    padding: 8px !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
    color: #DC354B !important;
    border-radius: 8px !important;
}

@media (max-width: 786px) {
    .header .socialicons{
        justify-content:flex-start !important;
    }
    .itw-logo {
        width:120px !important;
    }
    .logo-side img {
        width: 80px;
    }
    .logo-side p {
        margin-bottom: -6px;
    }
    .navbar-light .navbar-brand {
        flex-basis: 50%;
        margin: 0px;
    }
    .navbar-toggler-icon {
        width: 20px !important;
        height: 20px !important;
    }

    .Main-content-header {
        position: fixed;
        top: 0;
        width: 100%;
    }

    .navbar-collapse.collapse.show{
        position: absolute;
        background: #fff;
        width: 100%;
        top: 75px;
        right: 0px;
        box-shadow: 2px 4px 7px 2px #ccc;
        padding: 20px;
        text-align: center;
        z-index: 999;
    }

    .navbar-collapse.show{
        display: flex !important;
        align-items: flex-start !important;
        flex-flow: wrap-reverse;
    }
    .navbar-menu{
        margin-right: 15%;
        margin-left: 0px !important;
        text-align: left;
        align-items: baseline;
    }
    .tlt-name {
        font-size: 12px;
    }
    .top-header-dropdown {
        text-align: center;
        z-index: 9;
    }
    .itemblc {
        display: flex;
        align-items: center;
        padding: 4px 0px;
    }
    .navbar-expand{
        justify-content: flex-start !important;
        padding: 20px !important;
    }
    .navbar-expand .navbar-nav {
        flex-direction: column !important;
    }

    .header-topup {
        width: -webkit-max-content;
        width: max-content;
    }
    .box-header-clickaway{
        width: 30%;
        text-align: right;
    }
    .box-header-clickaway button{
        background: #fff;
        padding: 3px !important;
        border-radius: 2px !important;
    }
    .navbar {
        padding: 5px 0px !important;
    }
    .logo-flex{
        display: flex;
        text-align: center;
        align-items: start;
    }
    .logo-flex p{
        margin-left: 0px;
    }
}
