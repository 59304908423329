.Noresult {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    padding: 25px;
    margin: 25px 0;
    background: aliceblue;
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 150px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.resulttlthotel {
    font-size: 18px;
    font-weight: 500;
    margin: 15px 0;
    position: sticky;
    top: 0;
    z-index: 9;
    background: #fff;
    padding: 3px 15px;
    margin-left: -15px;
    width: 100%;
    border-radius: 8px;
}
.tlt-filter-header {
    display: flex;
    align-items: center;
}
.tltrangereset {
    flex-basis: 47%;
    font-size: 14px;
    font-weight: 500;
     color: tomato;
     cursor: pointer;
}
.tltrange-minimize {
    font-size: 22px;
    font-weight: 700;
    cursor: pointer;
}
.resulthoteltlt {
    width: 100%;
    padding: 15px;
    background: #ffffff;
    border-radius: 0px;
    position: relative;
    margin-bottom: 13px;
    box-shadow: none;
}
.items {
    padding: 10px;
    width: 100%;
    height: 165px;
    background: burlywood;
}
.labeltagtxt {
    padding: 3px 19px;
    background: red;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    position: absolute;
    z-index: 3;
    top: 25px;
    right: -13px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.labeltagtxt:after {
    content: "";
    border-top: 0 solid transparent;
    border-left: 0 solid red;
    border-right: 13px solid transparent;
    border-bottom: 3px solid red;
    position: absolute;
    top: -3px;
    right: 0;
}
.labeltagtxt:before {
    content: "";
    border: 14px solid red;
    border-left: 13px solid transparent;
    position: absolute;
    top: 0px;
    left: -14px;
}
.imgBoxgllry {
    width: 100%;
    background: #ccc;
    height: 200px;
    margin-top: 3px;
    border-radius: 10px;
    position: relative;
}
.imgBoxgllry img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: .5s ease;
}
.imgBoxgllry:hover .imghtl {
    opacity: 0.3;
  }
  
.imgBoxgllry:hover button.sethoverbtn {
opacity: 1;
}
.overlayout {
    position: absolute;
    top: 43%;
    left: 28%;
}
.imgBoxgllry-imni {
    display: flex;
    align-items: center;
    margin-top: 4px;
}
button.sethoverbtn {
    font-size: 12px;
    display: none;
}
.imgBoxgllry:hover .imghtl {
    opacity: 0.3;
  }
  .imgBoxgllry:hover  button.sethoverbtn {
   display: block;
  }
  .topscrolldiv {
    position: absolute;
    bottom: 0;
    right: 10%;
    z-index: 12;
}
.itemmini-tagimg {
    width: 48px;
    height: 48px;
    background: #ccc;
    font-size: 13px;
    text-align: center;
    border-radius: 5px;
    margin: 3px;
    cursor: pointer;
}
.itemmini-tagimg img {
    width: 100%;
    height: 100%;
}
.tlt-hotel {
    font-size: 15px;
    font-weight: 600;
    color: #0b78bc;
    margin-bottom: 10px;
}
.addresshotel p {
    font-size: 13px;
    color: #999999;
}
.distancehotel {
    margin-bottom: 5px;
}
.distancehotel svg {
    font-size: 14px;
    color: #999;
}
.distancehotel span {
    font-size: 13px;
    color: #999;
}
.ratingstarhotel {
    font-size: 14px;
    color: #999;
    display: flex;
    align-items: center;
}
img.starico {
    width: 15px;
}
.ratingstarhotel ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    padding: 0;
    margin: 5px;
}
.ratingstarhotel ul li {
    margin-right: 3px;
}
.ratingtlthotel {
    font-size: 12px;
    font-weight: 500;
    color: #999;
    margin-bottom: 5px;
}
span.uponrate {
    padding: 3px 15px;
    background: #28a745;
    border-radius: 15px;
    color: #fff;
    font-size: 12px;
    letter-spacing: 1px;
    text-align: center;
    margin-left: 9px;
}
.offernpricedtls {
    text-align: right;
    padding: 6px;
    margin-top: 50px;
}
.rateoff {
    font-size: 19px;
    color: #999999;
    text-decoration: line-through;
    font-weight: 500;
}
.rateopen {
    font-size: 22px;
    font-weight: 600;
    color: #000;
}
.btnselectroom {
    /* margin-top: 32px; */
    text-align: right;
}
.imgSliderPopup {
    min-width: 700px;
    height: 400px;
    background: #ccc;
    border-radius: 8px;
}

  /***gallery csss***/
  .thumbnail-slider-wrap {
    margin-top: 15px;
    height: 85px;
  }
  .thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
  }
  .thumbnail-slider-wrap .slick-track .slick-slide img {
    width: 70%;
  }

  .galleyheader {
    display: flex;
    align-items: center;
    width: 70%;
    margin: 0 auto;
}
.tltglry {
    flex-basis: 50%;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    padding: 12px 0;
    color: #fff;
}
.tabview {
    display: flex;
    width: 100%;
    padding: 6px 0 0 0;
    flex-basis: 80%;
}
.tabitem.active {
    border-bottom: 2px solid #fff;
    color: #fff;
    font-weight: 500;
}
.tabitem {
    font-size: 15px;
    font-weight: 400;
    margin-right: 20px;
    padding-bottom: 5px;
    padding-right: 4px;
    border-bottom: 2px solid transparent;
    color: #f1f1f1;
}
img.slickboximage {
    width: 100%;
    border-radius: 2px;
}
img.thumbnailimage {
    width: 97%;
    border-radius: 10px;
}
.slideimgbox {
    width: 70%;
    margin: 0 auto;
}
.thumbnailbox {
    width: 70%;
    margin: 0 auto;
}

.slider-box{
  
}

.galleryslider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 15px;
    background: #000000b8;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 12;
    
}
label.slickboxlabel {
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 17px;
    color: #fff;
    font-weight: 400;
    margin: 0;
    letter-spacing: .8px;
}
.btncls button svg {
    font-size: 30px;
    color: #DC354B;
}
.btncls {
    flex-basis: 20%;
    text-align: right;
}
/********/
.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 34px;
    line-height: 1;
    opacity: 1;
    color: #DC354B;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button.activecolor {
    border: 2px solid #0b78bc;
    background: #0b78bc;
    color: #fff;
}
.facilites span {
    font-size: 13px;
    font-weight: 500;
    margin-right: 9px;
    cursor: pointer;
}
.facilites span svg {
    font-size: 13px;
    margin-right: 4px;
}

.ul-activities, .ul-activities-1{
    padding: 0px 12px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
}

.ul-activities-1 li{
    margin-bottom: 10px;
}

.para-activities{
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0px;
    text-align: justify;
    
}

.tlt-hotel-1{
    font-size: 20px;
    font-weight: 600;
    color: rgb(51, 51, 51);
    margin-bottom: 0px;
}

.tlt-hotel-2{
    font-size: 15px;
    font-weight: 500;
    color: #444;
    margin-bottom: 5px;
}

.text-plan{
    font-size: 16px !important;
    color: #444 !important;
    font-weight: 600 !important;
}

.ratingstaractivity ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    padding: 0;
    margin: 5px 0px;
}

.ratingstaractivity ul li {
    margin-right: 3px;
}

/* activity search bar */

.searchbar-content {
    background: rgb(231 231 231);
    width: 100%;
    position: fixed;
    top: 80px;
    z-index: 5;
    border-bottom: 1px solid rgb(229, 229, 229);
}

.headerWrapper-txt2 {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
}

.searchmodal {
    left: 0;
    margin-top: -23px;
    box-sizing: border-box;
    border-radius: 8px;
    position: absolute;
    border: 1px solid #cfcfcf;
    border-top: none;
    top: 100%;
    width: 100%;
    overflow: hidden;
}
.searchmodal-search-box-container {
    display: flex;
    flex-direction: row;
    margin: 0;
    align-items: center;
    justify-content: center;
    padding: 7px 8px;
    background: #fff;
}

.searchmodal-search-box-container .MuiInputBase-formControl {
    width: 100%;
}

.searchmodal-search-box {
    flex: 1;
    outline: none;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    font-style: normal;
    letter-spacing: normal;
    border: none;
    background: transparent;
    color: #4a4a4a;
}
.searchmodal-content {
    border-top: 1px solid #cfcfcf;
    overflow: auto;
    height: auto;
    background: #fff;
    padding: 15px;
}

.searchmodal-horizontal-content-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.searchmodal-content-title {
    flex: 1;
    font-size: 14px;
    color: #4a4a4a;
}


.act-heading-1 {
    font-size: 28px;
    font-weight: 600;
    padding: 10px 0px 15px;
}

.farecontentBox {
    margin-bottom: 20px;
}

.calcBox {
    background: #fff;
    padding: 0px;
    border-radius: 6px;
}

.boxtlt {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.faredetailsBox {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 4px 0px;
}

.fareBox {
    flex-basis: 60%;
}

.faredetailprice {
    font-size: 16px;
    font-weight: 700;
    text-align: right;
    flex-basis: 40%;
}

.tlt-total {
    font-size: 16px;
    font-weight: 700;
}

.headerWrapper-input {
    display: flex;
    flex-direction: row;
}

.headerWrapper-left{
    flex-basis: 100%;
    justify-content: left;
    align-items: center;
    display: flex;

}

.headerWrapper-left-1 {
    flex-basis: 100%;
    justify-content: left;
    align-items: center;
    display: flex;
}

.headerWrapper-left img{
    width: 70%;
}
.headerWrapper-input h1{
    font-size: 17px;
    margin: 0;
    padding: 10px 0;
}

.headerWrapper-input h2{
    font-size: 18px;
    font-weight: 300;
}

.headerWrapper-right{
    flex-basis: 80%;
    text-align: right;
    color: #fff;
}

.headerWrapper-txt1 {
    font-size: 13px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 0px;
}


.date-activity{
    margin: 0px !important;
    padding-bottom: 20px !important;
    width: 100%;
}
.inputboxdetails input{
    font-size: 14px;
    padding: 5px 10px !important;
    height: 17px;
    background-color: #fff;
    border-radius: 3px;
}
.travellerdetails-wpr{
    padding: 15px 45px !important;
}

.tlttag {
    flex-basis: 100%;
    font-size: 14px;
    font-weight: 700;
    color: #dc354b;
    text-align: left;
}
.tlttagreset{
    flex-basis: 50%;
    font-size: 15px;
    font-weight: 500;
    color: red;
    text-align: right;
    cursor: pointer;
}
.listchecktag ul {
    padding: 0;
    list-style-type: none;
    padding: 0 7px;
    margin: 0;
}
label.labelcontrolcheck {
    width: 100%;
    margin: 0;
    /* margin-bottom: 10px; */
}


label.labelcontrolcheck span {
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 2px ;
}


.cancellation span{
    display: inline-block;
    height: 45px;
    width: 45px;
    text-align: center;
    /* border: 1px solid #46143d; */
    padding: 10px 15px;
    border-radius: 50%;
    background: #fef5f6;
    margin-right: 15px;
}
.cancellation .disabled{
    background: #9e9e9e26;
}

.cancellation .disabled svg{
    color: #9e9e9e82;
}

.margin-bottomitem {
    margin-bottom: 0px;
    margin-top: 40px;
}
.cancellation svg {
    color: #dc354b;
    font-size: 16px !important;
}
.result-right{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 5px;
}

/* 
.flight-card{
width:700px;
height: 610px;
margin: 100px auto;
border-radius: 50px;
overflow:hidden;
box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
}
.flight-card-header{
height: 150px;
width: 100%;
background: linear-gradient(to bottom, #264b76 0%,#002c5f 100%);
padding: 30px 50px;
border-bottom: 7px solid #6DBE47;
position: relative;
}
.flight-logo{
height: 110px;
width:100%;

}

.flight-logo img{
width: 150px;
}
.flight-data{
height:auto;
border-top: 2px solid #3E5177;
padding-top: 1em;
display: grid;
grid-template-columns: 2fr 1fr 1fr;
text-align: left;


}

.flight-card span{
display:block;
}
.flight-card .title{
color: #838EA8;
font-size: 16px;
}
.flight-card .detail{
font-size: 18px;
padding-top: 3px;
color: white;
}



.flight-card-content{
width: 100%;
height: auto;
display: inline-block;
}
.flight-card .flight-row{
width: 100%;
padding: 50px 50px;
display:grid;
grid-template-columns: 1fr 1fr 1fr;
}
.flight-card .plane{
text-align: center;
position: relative;
}
img{
width: 90px;
}
.flight-card .plane:before{
content: '';
width: 135px;
height: 3px;
background: #efefef;
position: absolute;
top: 45px;
left: -75px;
}
.flight-card .plane:after{
content: '';
width: 135px;
height: 3px;
background-color: #efefef;
position:absolute;
top: 45px;
right: -75px;

}
.flight-from{
text-align: left;
float:left;
}
.flight-to{
text-align: right;
float:right;
}

.flight-from,.flight-to span{
display:block;
}
.from-code, .to-code{
font-size: 60px;
color: #002C5F;
font-weight:200;
}
.flight-from,.flight-to  .from-city, .to-city{
font-size: 18px;
color: #002C5F;
font-weight:400;
}

.flight-details-row{
width:100%;
display:grid;
padding: 30px 50px;
grid-template-columns: 1fr 1fr 1fr; 
}
span{
display:block;
color:#002C5F;

}
.title{
color:#6a8597;
font-size:16px;
letter-spacing: 3px;
}
.flight-details-row .detail{
margin-top:.2em;
color:#002C5F;
font-size:18px;
}
.flight-operator{
text-align:left;
float:left;
}
.flight-class{
float:right;
text-align:right;
}
.flight-number{
padding-left:80px;
} */


.itinenary-list .MuiAccordionSummary-root{
    background: #fff;
    width: 100%;
}
.viewdtlshotelcls a{
    color: #DC354B !important;
}


.itinenary-list svg.MuiSvgIcon-root {
    font-size: 16px !important;
    color: #DC354B;
    background: #fff;
    border: 1px solid #DC354B;
    margin-top: 4px;
    border-radius: 50%;
}

.itinenary-list li svg.MuiSvgIcon-root {
    font-size: 13px !important;
    color: #DC354B;
    background: #fff;
    margin-top: 4px;
    border-radius: 50%;
    border: none;
}

.hotel-list-simple{
    list-style: none;
}


.hotel-list-simple li{
   font-size: 16px;
   margin-bottom: 5px;
   padding: 0px;
}
.MuiAccordionSummary-root{
    min-height: 30px !important;
    width: 100%;
    padding: 0 16px;
}
.MuiAccordion-root.Mui-expanded{
    margin-bottom: 0;
    margin-top: 0;
    border-top: 1px solid #ddd;
}
.MuiAccordionSummary-content p h5{
    margin-bottom: 0;
}
.MuiAccordionSummary-root.Mui-expanded{
    min-height: 30px;
}

.MuiAccordion-root{
    box-shadow: none !important;
    /* background: #DC354B21; */
    margin-bottom: 0;
}
.MuiAccordionSummary-content {
    margin: 5px 0;
}
.MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0;
    margin-left: 8px;
}

.faq-section-aacordian p{
    font-size: 12px;
}

.faq-section-aacordian  svg.MuiSvgIcon-root{
    font-size: 10px !important;
}

.faq-section .MuiPaper-elevation1 {
    box-shadow:none !important;
}

.highlights-section li{
    background: #fff;
    /* border: 1px solid #e6576c; */
}

.highlights-section li svg{ 
    font-size: 16px !important;
    background: #DC354B;
    color: #fff;
    border-radius: 50%;
    margin-top: 2px;
    padding: 2px;
}

.act-select-wrapper .MuiAccordionDetails-root {
    padding-bottom: 0px;
}
.act-select-wrapper .MuiAccordionDetails-root .MuiTextField-root {
    width: 100px;
}
.accordian-main{
    width: 100%;
}

.accordian-main svg{
    font-size: 20px !important;
    border: 1px solid;
    color: #000;
}

.customized-list .MuiFormControlLabel-root{
    margin: 1px 15px;
}

.customized-list .MuiFormControlLabel-label{
   font-size: 14px;
   margin-left: 7px;
}

.customized-list .MuiAccordionSummary-root.Mui-expanded {
    /* min-height: 30px;
    background: #DC354B14; */
    background: #f2f2f2;
    width: 100%;
}
.customized-list-preview .MuiAccordionSummary-root.Mui-expanded{
    background: #fff !important;
}
.customized-list-preview .MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0 0;
    margin-left: 8px;
}
.customized-list-preview .MuiFilledInput-root{
    width: 100px;
}
.finger-icon{
    color: #DC354B;
    transform: rotate(90deg);
    font-size: 16px !important;
    margin-top: -5px;
    margin-right: 6px;
}

.d-grid{
    display: grid;
}

.sticky-parent{
    justify-content: space-around;
    overflow: visible;
}

.sticky-child{
    position: sticky;
    position: -webkit-sticky;
    top: 75px;
}

.selectactivity-container .sticky-child{
    position: sticky;
    position: -webkit-sticky;
    top: 125px;
}
.hotelrow {
    margin: 25px 0;
    padding: 15px 10px 25px;
    background: #fff;
    border-radius: 12px;
}

.skeleton .card-img{
    box-shadow: 0 0px 3px 1px rgb(0 0 0 / 10%);
    padding: 20px;
}

.no-result-box {
    /* box-shadow: 2px 0px 6px 0px #ddd; */
    padding: 20px 20px;
    text-align: center;
    margin-bottom: 30px;
    border-radius: 10px;
}
.no-result-box img{
    width: 30%;
}
.no-result-box  .head-1{
}

.no-result-box  .head-2{
    font-size: 24px;
    font-weight: 700;
    color: #DC354B;
    margin-top: -20px;

}

/* .para-activities-div span{
    width: 100%;
  line-height: 1.2em;
  height: 3.6em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  margin-bottom: 0;
} */

.text-show-less {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: calc(2 * 1.5 * 14px);
}

.text-show-more {
    -webkit-line-clamp: unset;
    max-height: none;
}
.para-activities p{
    margin: 0;
}
.btn-show{
    background: transparent !important;
    font-size: 11px;
    color: #DC354B;
    padding: 0px 0px !important;
    float: right;
    min-width: max-content;
    display: inline-block;
    border: none;
}

.MuiSnackbar-root{
    left: auto !important;
    z-index: 99;
}

.custom-modal .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
}

.custom-modal .modal-footer button{
    font-size: 11px;
    padding: 4px 20px;
    font-weight: 600;
    margin-right: 10px;
    border-radius: 20px;
    color: #fff;
}


.preview-modal .modal-dialog {
    max-width: 1200px;
    margin: 1.75rem auto;
}

.preview-modal .modal-footer button{
    font-size: 12px;
    padding: 10px 20px;
    font-weight: 600;
}

.success-dialog h1 {
    color: #008000;
    /* font-family: "Nunito Sans", "Helvetica Neue", sans-serif; */
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 10px;
  }
.success-dialog  p {
    font-size:20px;
    margin: 0;
  }
.success-dialog i {
  color: #9ABC66;
  font-size: 100px;
}
.success-dialog .card {
  background: white;
  padding: 20px 60px 50px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #C8D0D8;
  display: inline-block;
  margin: 50px auto;
  width: 100%;
  text-align: center;
}
/* 
.success-dialog .card-body{
    border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;
} */
  
  .alert{
    background-color: #fff;
    font-size: 25px;
    font-family: sans-serif;
    text-align: center;
    /* height: 100px; */
    padding-top: 50px;
    position: relative;
    /* border: 1px solid #efefda; */
    border-radius: 2%;
    margin: 0;
  }

    .add-flight-btn p{
        color: #e9798a;
        font-size: 12px;
        padding-top: 6px;
        margin-bottom: 5px;
    }

    .add-flight-btn button{
        font-size: 13px;
        padding: 6px 10px;
        font-weight: 500;
        background: #DC354B !important;
        color: #fff;
        border: 1px solid #DC354B;
        border-radius:4px;
    }
  
  .alert::before{
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 100%;
    /* inset: 20px 0px 0px 100px; */
    font-size: 60px;
    /* line-height: 100px; */
    border: 5px solid gray;
    animation-name: reveal;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    display: block;
    margin: auto;
  }
  
  .alert>.alert-body{
    opacity:0;
    animation-name: reveal-message;
    animation-duration:1s;
    animation-timing-function: ease-out;
    animation-delay:1.5s;
    animation-fill-mode:forwards;
  }
  
  @keyframes reveal-message{
    from{
      opacity:0;
    }
    to{
      opacity:1;
    }
  }
  
  .success{
    color:green;
  }
  
  .success::before{
    content: '✓';
    background-color: #eff;
    box-shadow: 0px 0px 12px 7px rgba(200,255,150,0.8) inset;
    border : 5px solid green;
  }
  
  .error{
    color: red;
  }
  
  .error::before{
    content: '✗';
    background-color: #fef;
    box-shadow: 0px 0px 12px 7px rgba(255,200,150,0.8) inset;
    border : 5px solid red;
  }
  
  @keyframes reveal {
    0%{
      border: 5px solid transparent;
      color: transparent;
      box-shadow: 0px 0px 12px 7px rgba(255,250,250,0.8) inset;
      transform: rotate(1000deg);
    }
    25% {
      border-top:5px solid gray;
      color: transparent;
      box-shadow: 0px 0px 17px 10px rgba(255,250,250,0.8) inset;
      }
    50%{
      border-right: 5px solid gray;
      border-left : 5px solid gray;
      color:transparent;
      box-shadow: 0px 0px 17px 10px rgba(200,200,200,0.8) inset;
    }
    75% {
      border-bottom: 5px solid gray;
      color:gray;
      box-shadow: 0px 0px 12px 7px rgba(200,200,200,0.8) inset;
      }
    100%{
      border: 5px solid gray;
      box-shadow: 0px 0px 12px 7px rgba(200,200,200,0.8) inset;
    }
  }

.preview-flight-details .acti-flight-list {
    height: auto;
    overflow: hidden;
    padding: 0px;
    border: none;
}
.preview-flight-details .bgflightdetailscard{
    margin: 0;
    padding: 0;
}

.activity-preview .inputFieldBox {
    display: flex;
    margin-bottom: 10px;
    background: #F2F5F9;
    padding: 10px 0px;
    border-radius: 12px;
}

.activity-preview .sticky-child {
    position: sticky;
    position: -webkit-sticky;
    top: 115px;
}
.activity-preview .tlt-input-traveller {
    font-size: 11.5px;
    margin-bottom: 0px;
    font-weight: 500;
    color: #444;
}

.activity-preview .setinputBox p{
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 14px;
}

.evt-date{
    font-size: 12px;
    position: absolute;
    top: -5px;
    right: 5px;
}
.evt-date svg{
    font-size: 15px;
    margin: 2px -1px;
    color: #9d8152;
}
.evt-date p{
    margin-bottom: 0;
    font-size: 10px;
}

.evt-date .para-2{
    margin-bottom: 0px;
    font-size: 14px;
    margin-top: -4px;
    font-weight: 600 !important;
    /* color: #46143e; */
}

#filled-number{
    padding-top: 25px !important;
    padding-right: 12px;
    padding-bottom: 8px;
    padding-left: 12px;
}
.rateopen-strike{
    font-size: 13px;
}
.rateopen-strike strike{
    color: #9e9e9e;
}
.rateopen-strike span{
    font-size: 10px;
    color: orangered;
}

/* about section */
.category-section{
    display: flex;
    flex-direction: row;
    /* padding: 0 8%; */
}

.category-section .category-box{
    position: relative;
    height: 130px;
    flex-basis: 33%;
    width: 33%;
    padding: 10px 5px;
    border-radius: 8px;
    margin: 0 20px;
    background-color: #dd00100a;
}
.category-box-selected {
    border: 1px solid #DC354B;
}
.category-box h1{
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    margin: 10px
}
.category-box p{
    font-size: 12px;
    font-weight: 400;
    text-align: center;
}

.category-section .text-bold{
    text-align: center;
}
.category-input-box{
    text-align: center;
    margin: 10px 0 0px;
}
.category-input-box button{
    font-size: 13px;
    padding: 5px 15px;
    font-weight: 600;
    background: #DC354B !important;
    color: #fff;
    border: 1px solid #DC354B;
    border-radius: 4px;
}

.galleryslider{
    margin-top: 2% !important;
}
.image-gallery-slides img {
    border-radius: 16px;
}
    
.tabboxhotel .cancellation {
    border: 1px solid #fcdee2;
    border-radius: 50px;
}

.tabboxhotel .cancellation p{
    padding: 12px 0;
    font-size: 15px;
    font-weight: 400;
    color: #000;
}

@media (max-width: 786px){
    .cancellation span{
        height: 37px;
        width: 37px;
        padding: 5px 10px;
        margin-right: 10px;
    }
    .tabboxhotel .cancellation p{
        padding: 7px 0;
        font-size: 12px;
        font-weight: 400;
        color: #000;
    }
    .category-section {
        display: flex;
        flex-direction: column;
    }
    .category-section .category-box{
        position: relative;
        height: 130px;
        flex-basis: 100%;
        width: 100%;
        padding: 10px 5px;
        border-radius: 8px;
        margin: 0px 0 15px;
        background-color: #dd00100a;
    }
    .flitercolumstick{
        display: none;
        position: relative;
    }
    .monfilter{
        display: block !important;
    }
    .d-block-xs {
        display: block !important;
    }
    .result-right {
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 5px;
        padding: 0 20px;
    }

    .btnselectroom {
        padding: 0px 15px;
    }
    .offernpricedtls {
        text-align: left;
        padding: 15px 15px 5px;
    }
    .headerWrapper-input h1 {
        font-size: 15px;
    }
    .headerWrapper-input h2 {
        font-size: 14px;
    }
    .headerWrapper-txt1 {
        font-size: 10px;
    }
    .tlt-hotel-1 {
        font-size: 17px;
        margin-top: 10px;
    }
    .tlt-hotel-2 {
        font-size: 12px;
    }
    .no-result-box .head-2 {
        font-size: 16px;
        margin-top: -10px;
    }

    .searchbar-content {
        top: 63px !important;
    }

    .evt-date{
        font-size: 12px;
        position: relative;
        top: 0px;
        right: 0px;
        padding: 15px 15px 0px;
    }
    .custom-modal .modal-dialog {
        max-width: 100%;
        margin: 1.75rem 10px;
    }
}
