.about-us h1 {
    font-size: 20px;
}
.about-us {
    background: #fff;
    border-radius: 8px;
}
.about-us p {
    font-size: 14.5px;
    color: #444;
    display: flex;

}
.aboutimgbanner img {
    width: 100%;
    border-radius: 8px;
}
.aboutimgbanner {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
}
.about-us h3{
    font-size: 19px;
}
.about-us ul li {
    font-size: 14.5px;
    color: #828080;
}
.tlt-docs h1 {
    font-size: 25px;
    margin: 0 18px;
    margin-top: 10px;
}
.linksset {
    padding: 5px 30px;
    width: 1240px;
    margin: auto;
}
a.linkdiv-set {
    font-size: 14px;
    font-weight: 500;
}
p.linkdiv-set {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}
a.linkdiv-set {
    font-size: 12px;
    font-weight: 500;
    color: #fff !important;
}
.tlt-docs {
    background: linear-gradient(to right, #bb9349, #bb93497d );
    width: 100%;
    position: fixed;
    top: 80px;
    z-index: 5;
}
.privacy-box{
    position: absolute;
    margin-top: -24%;
    margin-left: 0%;
    text-align: center;
    align-items: center;
    background: #f0f8ff94;
}
.privacy-txt{
    font-size: 2rem !important;
    padding: 20px 30px;
    font-family: monospace;
}
.privacyimgbanner img{
    width: 100%;
    height: 250px;
    border-radius: 5px;
}   
.pp-para h1 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
}

.pp-para h2 {
    font-size: 15px;
    font-weight: 600;
    color: #000;
}
.pp-para-medius p{
    font-size: 16.5px;
    color: #444;
}
.pp-para b{
    color: #000;
    font-weight: 600;
    margin-right: 5px;
}
.pp-rightcontainer b{
    font-weight: 600;
}
.text-xs{
    font-size: 12px !important;
}

.searchbar-content {
    background: rgb(231 231 231);
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 5;
    border-bottom: 1px solid rgb(229, 229, 229);
    /* padding: 7px 0; */
}

.headerWrapper-input h4 {
    font-size: 17px;
    margin: 0;
    padding: 10px 0;
}

/* .activity-tab{
    margin: 40px;
} */
.MuiAccordion-root{
   box-shadow: none !important;
}

.faq-section-aacordian p{
   font-size: 12px;
   margin-left: 10px;
}

.faq-section-aacordian  svg.MuiSvgIcon-root{
   font-size: 10px !important;
}
  